/* CSS Properties For Bar Chart Component */
#bar-chart-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: inherit;
  height: inherit;
  overflow: scroll;
}
.bar-chart {
  width: inherit;
  height: inherit;
}
