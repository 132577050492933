/* CSS Properties For Linear & Circular Progress Bar Component */

/* Primary */
.progress-bar {
  width: 3.125rem;
  height: 0.5rem;
  border: 1px solid #f2f3f7;
  display: flex;
}
.progress,
.progress-scope1,
.progress-scope2,
.progress-scope3 {
  height: 100%;
}
.progress,
.progress-scope1 {
  background-color: #a1dd8c;
}
.progress-scope2 {
  background-color: #0bcbb3;
}
.progress-scope3 {
  background-color: #0096fa;
}
.remaining-progress {
  height: 100%;
  background-color: #f2f3f7;
}
.remaining-progress-report {
  height: 100%;
  background-color: rgb(188, 121, 121);
}
