/* CSS Properties For Full Application */

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&family=Roboto:wght@400;700&display=swap");
@import "react-loading-skeleton/dist/skeleton.css";
@import "components.css";
@import "config.css";
@import "layouts.css";
@import "pages.css";
@import "visualizations.css";

/* Color Palette */
:root {
  /* Colors */
  --app-black: #000000;
  --background-color-nav: #232c33;
  --text-color-main: var(--background-color-nav);
  --text-color-label: #637e92;
  --text-color-nav: #c7cacb;
  --background-color-main: #f2f3f7;
  --app-light-gray: #f5f5f5;
  --app-medium-gray: var(--background-color-main);
  --app-white: #ffffff;
  --app-green: #00a63f;
  --app-blue: #0075d2;
  --app-red: #bd0606d0;
  --app-orange: #ff8c00;
  /* Font Size */
  --cursive-font: "Snell Roundhand", cursive;
  --font-tiny: 0.65rem;
  --font-vsmall: 0.75rem;
  --font-small: 0.85rem;
  --font-small-medium: 1rem;
  --font-medium: 1.25rem;
  --font-medium-large: 1.5rem;
  --font-large: 2.5rem;
  --font-xlarge: 2.85rem;
  --font-xxlarge: 3.75rem;
  --table-row-font-size-annotation: var(--font-vsmall);
  --blurb-group-font-size: var(--font-vsmall);
  --font-page-title: 1.75rem;
  --font-weight-reg: 400;
  /* Padding */
  --padding-025: 0.25rem;
  --padding-1: 1rem;
  --padding-2: 2rem;
  /* Header */
  --header-display: flex;
  --header-display-flex-direction: row;
  --header-display-justify-content: space-between;
  --header-display-align-items: center;
  --header-display-padding: 0.5rem 1rem;
}

/* General Properties For The Whole Application */
* {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
}

body {
  background-color: var(--background-color-main);
}
/* Text Font Size Properties */
.font-small {
  font-size: var(--font-small);
}
.font-small-medium {
  font-size: var(--font-small-medium);
  line-height: 1.25rem;
}
.font-medium {
  font-size: var(--font-medium);
}
.font-medium-large {
  font-size: var(--font-medium-large);
}
.font-large {
  font-size: var(--font-large);
}
.font-cursive {
  font-family: var(--cursive-font);
}
.font-metric-value {
  font-size: var(--font-large);
  line-height: 1.5rem;
}
.font-metric-value-large {
  font-size: var(--font-xlarge);
}
.strikethrough-text {
  text-decoration: line-through;
}
/* Font Properties */
.card-title {
  color: var(--text-color-main);
  font-size: var(--font-small-medium);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  align-items: center;
  display: flex;
}
.card-subtitle {
  color: var(--text-color-label);
  font-size: var(--font-small-medium);
  font-style: normal;
  font-weight: var(--font-weight-reg);
  line-height: normal;
}
.card-text {
  color: var(--text-color-main);
  font-size: var(--font-small);
  font-style: normal;
  font-weight: var(--font-weight-reg);
  line-height: normal;
}
.card-subtext {
  color: var(--text-color-label);
  font-size: var(--font-small);
  font-style: normal;
  font-weight: var(--font-weight-reg);
  line-height: normal;
}
.card-text-min {
  color: var(--text-color-main);
  font-size: var(--font-vsmall);
  font-style: normal;
  font-weight: 500;
}
.font-color-gray {
  color: #666;
}
.font-color-blue {
  color: var(--app-blue);
}
.chart-card-container-group {
  background-color: var(--app-white);
  border-radius: 1rem;
  padding: 1.75rem;
  overflow: hidden;
}
.chart-card-title-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.sidebar-button-center {
  display: flex;
  justify-content: center;
  margin: 6px;
}
.flex-row-space-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.submit-button {
  background: var(--app-green);
  border-radius: 0.25rem;
  color: var(--app-white);
  font-weight: 500;
  font-size: var(--font-vsmall);
  line-height: 23px;
  letter-spacing: 0em;
  padding: 0.5rem 1rem;
  border: none;
  cursor: pointer;
}
.download-button {
  background: var(--app-green);
  border-radius: 0.25rem;
  color: var(--app-white);
  font-weight: 500;
  font-size: var(--font-small);
  line-height: 23px;
  letter-spacing: 0em;
  padding: 0.5rem 1rem;
  border: none;
  cursor: pointer;
}
/* Scroll Bar Properties */
::-webkit-scrollbar {
  width: 7px;
  height: 10px;
  border-radius: 12px;
  margin-right: 12px;
}

::-webkit-scrollbar-track {
  background-color: none;
  border-radius: 12px;
}

::-webkit-scrollbar-thumb {
  background-color: #d9d9d9;
  border-radius: 4px;
  border-radius: 12px;
  height: 20px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #cccbcb;
  border-radius: 12px;
}
.terms-services-link {
  color: var(--app-blue);
  text-decoration: underline;
  cursor: pointer;
}

/* Mobile View */
@media screen and (max-width: 900px) {
  :root {
    --font-tiny: 0.55rem;
    --font-vsmall: 0.65rem;
    --font-small: 0.75rem;
    --font-small-medium: 0.9rem;
    --font-medium: 1.15rem;
    --font-medium-large: 1.4rem;
    --font-page-title: 1.5rem;
    --font-large: 2rem;
    --font-xlarge: 2.5rem;
    --font-xxlarge: 3.25rem;
  }
}
