/* CSS Properties For Pagination Component */

/* Primary */
.pagination-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.pagination-item {
  display: flex;
  justify-content: center;
  align-items: center;
}
.pagination-item button,
.pagination-arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border: none;
  border-radius: 6px;
  background-color: var(--app-light-gray);
  font-size: var(--font-small-medium);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  margin: 5px;
}
