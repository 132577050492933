/* CSS Properties For Metric Tile Group Component */

/* Primary */
.metric-tile-group-container {
  border-radius: 16px;
  overflow: hidden;
  width: inherit;
  height: inherit;
  position: relative;
  background-color: var(--app-white);
  min-height: 300px;
}
.metric-tile-group-title {
  text-align: left;
  padding: var(--padding-2);
  align-items: flex-end;
}
.metric-tile-group-subheader {
  text-align: left;
  padding: 0rem 2rem;
  margin-top: -1rem;
  align-items: flex-end;
  cursor: pointer;
}
.metric-tile-group-metric-group {
  display: flex;
  align-items: flex-end;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
}
.metric-tile-group-metric {
  font-size: var(--font-xxlarge);
  font-weight: 800;
  letter-spacing: 0em;
  text-align: left;
  color: var(--text-color-main);
  line-height: 70px;
}
.metric-tile-group-unit {
  font-size: var(--font-small-medium);
  font-weight: 500;
  letter-spacing: 0em;
  text-align: left;
  color: var(--text-color-label);
  line-height: 45px;
  margin-left: 2rem;
}
.metric-tile-group-sub-comment-group {
  display: flex;
  font-size: var(--font-small-medium);
  font-style: normal;
  font-weight: var(--font-weight-reg);
  line-height: normal;
  padding: 0.5rem 2rem;
}
.metric-tile-group-sub-comment-group-1 {
  color: var(--app-green);
  margin-right: 0.5rem;
}
.metric-tile-group-sub-comment-group-1-down {
  color: #b21919;
  margin-right: 0.5rem;
}
.metric-tile-group-sub-comment-group-2 {
  color: var(--text-color-label);
}
.metric-tile-group-svg-children-container {
  position: absolute;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  padding: var(--padding-1);
  background-color: transparent;
  color: var(--app-black);
  border: none;
  z-index: 5;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
}
/* SVG Container CSS */
.svg-container {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 35%;
}
.svg-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.svg-overlay:nth-child(1) {
  z-index: 2;
}

.svg-overlay:nth-child(2) {
  z-index: 3;
}

.svg-overlay:nth-child(3) {
  z-index: 1;
}

@media screen and (max-width: 900px) {
  .metric-tile-group-container {
    min-height: inherit;
  }
  .metric-tile-group-svg-children-container {
    bottom: 0rem;
    left: 70%;
  }
}
