/* CSS Properties For Overview Page Specific Components */
/* Main Container */
#overview-page-container {
  display: grid;
  grid-template-columns: 73% 27%;
  width: 100%;
  grid-gap: 1.5rem;
}
/* Industry Carbon Rating Card */
#industry-carbon-rating-container {
  width: 90%;
  height: 350px;
  border-radius: 16px;
  background-color: var(--app-white);
  padding: var(--padding-1);
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}
.industry-carbon-rating-title {
  text-align: left;
  margin-top: 0.5rem;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.industry-carbon-rating-visual-group {
  margin-top: 1.5rem;
  height: 250px;
}
.industry-carbon-rating-plain-text-label {
  color: var(--text-color-label);
  font-style: normal;
  font-weight: var(--font-weight-reg);
  line-height: normal;
  text-align: center;
  margin-top: -3rem;
  line-height: 1.5rem;
  overflow-wrap: break-word;
}

#industry-carbon-rating:hover::before {
  position: absolute;
  left: 60%;
  transform: translate(-50%);
  margin-top: 1rem;
  color: var(--app-white);
  background: var(--app-light-gray);
  border-radius: 5px;
  padding: 10px;
  width: 350px;
  text-align: center;
  z-index: 5;
  content: "Monthly Industry Carbon Rating is only available for calculation after 15 days of data collection";
  font-size: var(--font-small);
}
/* Total Emissions Footprint Breakdown Card */
#emission-footprint-breakdown-container {
  width: 42.5rem;
  flex-shrink: 0;
  border-radius: 16px;
  background-color: var(--app-white);
  padding: var(--padding-1);
  display: grid;
  grid-template-columns: 50% 50%;
}
.emission-footprint-metric-tile-group-metric {
  font-weight: 800;
  letter-spacing: 0em;
  text-align: left;
  color: var(--text-color-main);
  line-height: 70px;
}
.emission-footprint-legend-group {
  display: grid;
  grid-template-columns: 30% 70%;
  width: 100%;
  gap: 1rem;
  margin-top: 2rem;
}
.emission-footprint-value-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.emission-footprint-legend-value {
  color: var(--text-color-main);
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}
.emission-footprint-label-group {
  display: flex;
  flex-direction: column;
  justify-content: left;
}
.emission-footprint-label {
  color: var(--text-color-main);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  line-height: 1.25rem;
}
.emission-footprint-label-def {
  color: var(--text-color-main);
  font-style: normal;
  font-weight: var(--font-weight-reg);
  line-height: normal;
  line-height: 1.25rem;
}
.emission-footprint-chart-group {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.emission-footprint-chart-container {
  width: 15rem;
  height: 15rem;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-top: 4rem;
}

/* Vehicle Emissions Card */
.hev-container {
  background-color: var(--app-white);
  padding: 1.75rem;
  border-radius: 1rem;
  flex-shrink: 0;
}
.hev-title-container {
  display: grid;
  grid-template-columns: 75% 25%;
  width: 100%;
}
.hev-title-group {
  text-align: left;
  margin-top: 0.5rem;
  align-items: center;
  display: flex;
  flex-direction: row;
}
.hev-table-container {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.table-observation-number-col {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  text-align: center;
  line-height: 20px;
  font-weight: bold;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: var(--table-row-font-size-annotation);
}
.table-observation-number-col-text {
  font-weight: 700;
  text-align: center;
  color: var(--app-white);
  font-size: var(--table-row-font-size-annotation);
}
.table-observation-number-col-red,
.vehicle-emissions-legend-benchmark-red {
  background: #ff1f2e;
}
.table-observation-number-col-orange {
  background: #f97f3d;
}
.table-observation-number-col-yellow {
  background: #f7ba1e;
}
.table-observation-number-col-green,
.vehicle-emissions-legend-benchmark-green {
  background: var(--app-green);
}
.table-emission-col-group {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.table-emission-col-group-value {
  margin-right: 0.5rem;
}
.table-emission-col-group-label {
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--text-color-label);
  font-size: var(--table-row-font-size-annotation);
}

/* The Group Containing Total Emission Footprint, Industry Carbon Rating & Highest Emitting Vehicles */
.overview-page-left-group {
  display: grid;
  grid-template-columns: 30% 70%;
  gap: 2rem;
  margin-bottom: 1.5rem;
}

/* The Group Containing Cost of Carbon & Fuel Consumption */
.overview-page-right-group {
  display: flex;
  flex-direction: column;
  width: 95%;
}
.overview-page-cost-of-carbon {
  border-radius: 16px;
}
.overiew-page-right-switch-carousel {
  padding-bottom: 2rem;
  padding-top: 2rem;
  background-color: var(--app-white);
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

/* Mobile View */
@media screen and (max-width: 900px) {
  #overview-page-container,
  .overview-page-left-group {
    display: flex;
    flex-direction: column;
  }
  .svg-overlay {
    display: none;
  }
  #industry-carbon-rating-container {
    width: 94%;
    height: fit-content;
  }
  .industry-carbon-rating-visual-group {
    height: 150px;
  }
  .industry-carbon-rating-plain-text-label {
    margin-top: -1.5rem;
  }
  .overview-page-right-group {
    width: 100%;
  }
  .overiew-page-right-switch-carousel {
    width: 100%;
  }
}
