/* CSS Properties For Registration Form Specific Components */

.hubspot-form-middle {
  width: 75%;
  height: 95vh;
  overflow-y: scroll;
  margin: 2px auto;
  padding: 20px;
  border-radius: 6px;
  z-index: 1;
  background-color: var(--app-white);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
}

.hubspot-form-middle-padding {
  width: 70%;
  height: 82vh;
  overflow-y: hidden;
  margin: 2px auto;
  padding: 20px;
  border-radius: 6px;
  z-index: 1;
  background-color: var(--app-white);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
}

.hubspot-actual-form {
  padding: 10px 100px 10px 100px;
}
.hubspot-invited-user-form {
  margin-bottom: 10px;
}
.hubspot-form-logo-header-group {
  display: var(--header-display);
  flex-direction: var(--header-display-flex-direction);
  justify-content: var(--header-display-justify-content);
  align-items: var(--header-display-align-items);
  padding: var(--header-display-padding);
}
.hubspot-form-logo-header-group img {
  width: 175px;
  max-width: 175px;
}
.hubspot-form-header-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: auto;
}
.hubspot-form-header-group p {
  font-style: normal;
  font-weight: 500;
  font-size: var(--font-large);
  line-height: normal;
  margin-bottom: 0.25rem;
  color: var(--text-color-main);
}
/* ProgressBar */
.registration-form-timeline-group {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  align-items: center;
  margin-left: 5%;
  width: 90%;
  margin-top: auto;
}
.registration-form-timeline-group p {
  font-style: normal;
  font-weight: 500;
  font-size: var(--font-vsmall);
  line-height: normal;
  margin-bottom: 0.25rem;
  color: var(--text-color-main);
}
.registration-form-timeline-subgroup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: auto;
  text-align: center;
}

/* Refactor ALL OF HUBSPOT FORM LAYOUT */
/* #hubspotForm .hs-form-private {
  align-items: center;
  width: 100%;
} */
#hubspotForm input[type="text"],
#hubspotForm input[type="email"] {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: var(--font-vsmall);
  width: 95%;
  margin-top: 0.5rem;
}
#hubspotForm input[type="text"]::placeholder,
#hubspotForm input[type="email"]::placeholder {
  color: #999;
  font-size: var(--font-small);
  text-decoration: italic;
}
#hubspotForm input[type="text"]:focus,
#hubspotForm input[type="email"]:focus {
  border-color: #666;
  outline: none;
}
#hubspotForm input[type="text"]:hover,
#hubspotForm input[type="email"]:hover {
  border-color: #666;
}
#hubspotForm input[type="submit"] {
  background-color: var(--app-green);
  border: none;
  color: var(--app-white);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0.75rem;
  border-radius: 5px;
  cursor: pointer;
  font-size: var(--font-small);
  position: relative;
}
#hubspotForm .hs-form-checkbox {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 0.5rem;
}
#hubspotForm .hs-form-checkbox label span {
  font-size: var(--font-vsmall);
  color: var(--text-color-label);
  margin-left: 0.5rem;
}

/* Flex End Button Group */
#hubspotForm .actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
#hubspotForm .actions {
  margin-right: 0.25rem;
}

#hubspotForm li {
  list-style-type: none;
}
#hubspotForm li label {
  font-size: var(--font-vsmall);
  color: var(--text-color-label);
}
#hubspotForm .hs-form-required {
  color: var(--app-red);
  margin-left: 0.25rem;
}
#hubspotForm .hs-form-field {
  margin-bottom: 1rem;
  font-size: var(--font-small);
}

#hubspotForm select {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: var(--font-small);
  width: 95%;
  margin-top: 0.5rem;
}
.hs-input:not([type="file"]) {
  background-color: #f5f8fa;
}
