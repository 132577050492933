/* CSS Properties For GaugeChart Component */
#gauge-chart-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 900px;
  height: 900px;
  overflow: scroll;
}
.gauge-chart {
  width: 900px;
  height: 900px;
}
