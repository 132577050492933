/* CSS Properties For Button Component */
.primary-blue-button {
  background-color: var(--app-blue);
  border: none;
  color: var(--app-white);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0.75rem;
  border-radius: 5px;
  cursor: pointer;
  font-size: var(--font-small);
  position: relative;
}
.primary-blue-button:disabled {
  background-color: var(--app-medium-gray);
  color: var(--text-color-label);
  cursor: not-allowed;
}
.secondary-blue-button {
  background-color: transparent;
  border: 1px solid var(--app-blue);
  color: var(--app-blue);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0.75rem;
  border-radius: 5px;
  cursor: pointer;
  font-size: var(--font-small);
}
.cancel-solid-red-button {
  background-color: var(--app-red);
  border: none;
  color: var(--app-white);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0.75rem;
  border-radius: 5px;
  cursor: pointer;
  font-size: var(--font-small);
  position: relative;
}
.cancel-red-button {
  background-color: transparent;
  border: 1px solid var(--app-red);
  color: var(--app-red);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0.75rem;
  border-radius: 5px;
  cursor: pointer;
  font-size: var(--font-small);
}
.cancel-red-button {
  background-color: transparent;
  border: 1px solid var(--app-red);
  color: var(--app-red);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0.75rem;
  border-radius: 5px;
  cursor: pointer;
  font-size: var(--font-small);
}
.primary-white-button {
  background-color: var(--app-white);
  border: none;
  color: var(--text-color-main);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0.75rem;
  border-radius: 5px;
  cursor: pointer;
  font-size: var(--font-small);
  position: relative;
  box-shadow: 0px 4px 14px 0px #0000001a;
}
.primary-white-button:disabled {
  background-color: var(--app-medium-gray);
  color: var(--text-color-label);
  cursor: not-allowed;
}
/* Flex End Button Group */
.flex-end-button-group {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.flex-end-button-group button {
  margin-right: 0.25rem;
}
