/* CSS Properties For Modal Component */

/* Primary */
.modal-background-class {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: transparent;
  z-index: 999; /* corresponds to z-50 */
}
.modal-class {
  position: fixed;
  left: 0%;
  top: 7.7vh;
  right: 0;
  bottom: 0;
  background: rgba(217, 217, 217, 0.7);
  z-index: 999; /* corresponds to z-50 */
}
.modal-div {
  position: fixed;
  top: 50%;
  left: 52%;
  transform: translate(-50%, -50%);
  background-color: var(--app-white);
  padding: var(--padding-2);
  z-index: 10000;
  max-height: 50%;
  width: 50%;
  overflow-y: scroll;
  border-radius: 6px;
}
.large-modal-div {
  position: fixed;
  top: 50%;
  left: 52%;
  transform: translate(-50%, -50%);
  background-color: var(--app-white);
  padding: var(--padding-2);
  z-index: 10000;
  min-height: 50%;
  max-height: 60%;
  width: 70%;
  overflow-y: scroll;
  border-radius: 6px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.close-modal-x {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  cursor: pointer;
  font-size: var(--font-medium-large);
  color: var(--text-color-main);
}
