/* CSS Properties For Verification Code Component */
.verification-code-component {
  position: relative;
  justify-content: center;
  display: flex;
  flex-direction: column;
  width: 75%;
  margin: 5px;
}
.verification-code-component p {
  color: var(--text-color-main);
  font-size: var(--font-small-medium);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0.5rem;
}
.verification-code-input-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;
}
.individual-verification-code-input {
  width: 40px;
  height: 40px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  text-align: center;
  font-size: var(--font-medium);
  font-weight: 600;
  color: #000;
  background-color: #fff;
  outline: none;
  transition: all 0.3s ease;
}
.verification-code-reset-button {
  background-color: var(--background-color-nav);
  color: #fff;
  border: none;
  border-radius: 6px;
  padding: 5px 5px;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  cursor: pointer;
  outline: none;
  transition: all 0.3s ease;
}
