.demo-trial-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-family: "Times New Roman", Times, serif;
}
.demo-trial-modal h1 {
  margin: 0;
  margin-bottom: 20px;
  text-align: center;
}
.demo-trial-modal h2 {
  margin: 0;
  margin-bottom: 20px;
  margin-top: 20px;
}
.demo-trial-modal p {
  margin: 0;
  margin-bottom: 20px;
}
.demo-trial-logo {
  align-items: center;
  height: 50px;
  width: 200px;
  margin-bottom: 20px;
}
