/* CSS Properties For All Config Files */

/* Legends CSS */
#vehicle-emissions-tile-legend,
#vehicle-emissions-tile-blurb,
#industry-carbon-rating-tile-blurb,
#emission-footprint-tile-blurb,
#cost-carbon-tile-blurb,
#fuel-consumption-tile-blurb,
#industry-carbon-rating-tile-legend,
#scope1-emissions-chart-tile-legend,
#scope1-emissions-chart-tile-blurb,
#scope2-emissions-chart-tile-blurb,
#scope1-emissions-card-tile-blurb,
#scope2-emissions-card-tile-blurb,
#emission-reduction-target-tile-blurb,
#forecasting-chart-tile-blurb,
#emissions-per-vehicle-tile-blurb,
#emissions-per-vehicle-tile-legend,
#avg-emissions-per-vehicle-tile-blurb,
#avg-emissions-per-vehicle-tile-legend,
#total-idle-time-tile-blurb,
#total-idle-time-emissions-tile-blurb,
#drive-status-tile-blurb,
#drive-idle-ratio-tile-blurb,
#days-active-tile-blurb,
#lifecycle-emissions-tile-blurb {
  margin-left: 10px;
  color: var(--text-color-label);
}
#vehicle-emissions-tile-legend:hover + #vehicle-emissions-legend-group,
#vehicle-emissions-tile-blurb:hover + #vehicle-emissions-blurb-group,
#industry-carbon-rating-tile-blurb:hover + #industry-carbon-rating-blurb-group,
#emission-footprint-tile-blurb:hover + #emission-footprint-blurb-group,
#cost-carbon-tile-blurb:hover + #cost-carbon-blurb-group,
#fuel-consumption-tile-blurb:hover + #fuel-consumption-blurb-group,
#industry-carbon-rating-tile-legend:hover
  + #industry-carbon-rating-legend-group,
#scope1-emissions-chart-tile-legend:hover
  + #scope1-emissions-chart-legend-group,
#scope1-emissions-chart-tile-blurb:hover + #scope1-emissions-chart-blurb-group,
#scope2-emissions-chart-tile-blurb:hover + #scope2-emissions-chart-blurb-group,
#scope1-emissions-card-tile-blurb:hover + #scope1-emissions-card-blurb-group,
#scope2-emissions-card-tile-blurb:hover + #scope2-emissions-card-blurb-group,
#emission-reduction-target-tile-blurb:hover
  + #emission-reduction-target-blurb-group,
#forecasting-chart-tile-blurb:hover + #forecasting-chart-blurb-group,
#emissions-per-vehicle-tile-blurb:hover + #emissions-per-vehicle-blurb-group,
#emissions-per-vehicle-tile-legend:hover + #emissions-per-vehicle-legend-group,
#avg-emissions-per-vehicle-tile-blurb:hover
  + #avg-emissions-per-vehicle-blurb-group,
#avg-emissions-per-vehicle-tile-legend:hover
  + #avg-emissions-per-vehicle-legend-group,
#total-idle-time-tile-blurb:hover + #total-idle-time-blurb-group,
#total-idle-time-emissions-tile-blurb:hover
  + #total-idle-time-emissions-blurb-group,
#drive-status-tile-blurb:hover + #drive-status-blurb-group,
#drive-idle-ratio-tile-blurb:hover + #drive-idle-ratio-blurb-group,
#days-active-tile-blurb:hover + #days-active-blurb-group,
#lifecycle-emissions-tile-blurb:hover + #lifecycle-emissions-blurb-group {
  display: flex;
  flex-direction: column;
}
#vehicle-emissions-legend-group,
#vehicle-emissions-blurb-group,
#industry-carbon-rating-blurb-group,
#emission-footprint-blurb-group,
#cost-carbon-blurb-group,
#fuel-consumption-blurb-group,
#scope1-emissions-chart-legend-group,
#scope1-emissions-chart-blurb-group,
#scope2-emissions-chart-blurb-group,
#scope1-emissions-card-blurb-group,
#scope2-emissions-card-blurb-group,
#emission-reduction-target-blurb-group,
#forecasting-chart-blurb-group,
#emissions-per-vehicle-blurb-group,
#emissions-per-vehicle-legend-group,
#total-idle-time-blurb-group,
#total-idle-time-emissions-blurb-group,
#drive-status-blurb-group,
#drive-idle-ratio-blurb-group,
#days-active-blurb-group,
#lifecycle-emissions-blurb-group {
  align-items: center;
  margin-top: 100px;
  padding: 20px;
  position: absolute;
  background: var(--background-color-nav);
  border-radius: 6px;
  display: none;
  color: var(--app-white);
  max-width: 500px;
  z-index: 5;
  font-size: var(--blurb-group-font-size);
  line-height: 1.25rem;
}
#avg-emissions-per-vehicle-blurb-group,
#avg-emissions-per-vehicle-legend-group {
  align-items: center;
  margin-top: 200px;
  padding: 20px;
  position: absolute;
  background: var(--background-color-nav);
  border-radius: 6px;
  display: none;
  color: var(--app-white);
  max-width: 500px;
  z-index: 5;
  font-size: var(--blurb-group-font-size);
  line-height: 1.25rem;
}
#industry-carbon-rating-legend-group {
  margin-top: 5px;
  padding: 10px;
  position: absolute;
  background: var(--background-color-nav);
  border-radius: 6px;
  display: none;
  color: var(--app-white);
  max-width: 500px;
  z-index: 5;
  font-size: var(--blurb-group-font-size);
  line-height: 1.5rem;
}
#vehicle-emissions-blurb-group,
#scope1-emissions-chart-legend-group,
#emissions-per-vehicle-blurb-group {
  left: 300px;
}
#vehicle-emissions-legend-group,
#scope1-emissions-chart-blurb-group,
#emissions-per-vehicle-legend-group {
  left: 350px;
}
#total-idle-time-emissions-blurb-group {
  left: 600px;
}
#industry-carbon-rating-blurb-group,
#industry-carbon-rating-legend-group,
#forecasting-chart-blurb-group {
  left: 850px;
}
.vehicle-emissions-legend-subgroup,
.industry-carbon-rating-legend-subgroup {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.vehicle-emissions-legend-benchmark-green,
.vehicle-emissions-legend-benchmark-red,
.icr-legend-subgroup-red,
.icr-legend-subgroup-orange,
.icr-legend-subgroup-yellow,
.icr-legend-subgroup-ygreen,
.icr-legend-subgroup-green {
  border-radius: 50%;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  text-align: center;
  line-height: 20px;
  margin: 0px 10px;
  align-items: center;
  justify-content: center;
  display: flex;
}
.icr-legend-subgroup-red {
  background-color: rgba(255, 31, 46, 1);
}
.icr-legend-subgroup-orange {
  background-color: rgba(249, 127, 61, 1);
}
.icr-legend-subgroup-yellow {
  background-color: rgba(244, 224, 77, 1);
}
.icr-legend-subgroup-ygreen {
  background-color: rgba(11, 203, 179, 1);
}
.icr-legend-subgroup-green {
  background-color: rgba(0, 166, 63, 1);
}
