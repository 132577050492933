/* CSS Properties For Loading Spinner Component */

/* Primary */
.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10vh;
  width: 100vw;
}
