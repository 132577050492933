/* CSS Properties For custom-Radio Item Component */

/* Primary Horizonal RadioItemGroup */
.div-radio-item {
  display: flex;
  justify-content: space-between;
  width: fit-content;
}
.div-radio-item:hover {
  cursor: pointer;
  color: var(--app-black);
}
.div-radio-item.selected {
  color: var(--app-black);
}
.div-radio-item input[type="radio"] {
  color: var(--app-black);
  margin-right: 1rem;
  margin-left: 1rem;
  accent-color: #232323;
}
.div-radio-item input[type="radio"]:disabled {
  cursor: not-allowed;
}

/* Vertical Radio Item Group */
.div-radio-item-vertical {
  display: grid;
  width: 100%;
  padding: var(--padding-025);
  gap: 1rem;
  align-items: center;
}
.div-radio-item-vertical:hover,
.div-radio-item-vertical-no-gap:hover {
  cursor: pointer;
  color: var(--app-black);
}
.div-radio-item-vertical.selected,
.div-radio-item-vertical-no-gap.selected {
  color: var(--app-black);
}
.div-radio-item-vertical input[type="radio"],
.div-radio-item-vertical-no-gap input[type="radio"] {
  color: var(--app-black);
  margin-right: 1rem;
  accent-color: #232323;
}
.radio-item-vertical-option.selected,
.radio-item-vertical-no-gap-option.selected {
  background-color: var(--app-light-gray);
}
.div-radio-item-vertical-no-gap {
  display: grid;
  grid-template-rows: repeat(2, minmax(0, 1fr));
  width: 100%;
  padding: var(--padding-025);
  align-items: center;
}
/* Primary Custom Radio Item */
.custom-radio-item-container {
  display: flex;
  border-radius: 6px;
  margin-top: 8px;
}
.custom-radio-item-option {
  padding: 8px 8px;
  cursor: pointer;
}
.custom-radio-item-option.selected {
  background-color: rgb(26, 132, 212);
  color: var(--app-white);
}
.custom-radio-item-option:not(.selected) {
  background-color: rgba(0, 116, 210, 0.25);
}

/* Secondary Custom Radio Item - Linear */
.custom-radio-item-container-linear {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  width: 100%;
  color: var(--background-color-nav);
}
.custom-radio-item-option-linear {
  padding: 6px 6px;
  cursor: pointer;
  width: 100%;
  text-align: center;
}
.custom-radio-item-option-linear.selected {
  font-weight: 600;
  border-bottom: 1px solid var(--app-black);
  color: var(--app-black);
}

/* Sidebar Radio Item Group*/
.div-radio-item-sidebar {
  display: grid;
  grid-template-rows: repeat(2, minmax(0, 1fr));
  width: 100%;
  padding: var(--padding-025);
  gap: 1rem;
  align-items: center;
  border-radius: 6px;
}
.div-radio-item-sidebar:hover {
  cursor: pointer;
  color: var(--app-black);
}
.div-radio-item-sidebar.selected {
  color: var(--app-black);
}
.div-radio-item-sidebar input[type="radio"] {
  display: none;
}
.div-radio-item-sidebar-option {
  padding: 10px;
  cursor: pointer;
  border-radius: 6px;
}
.div-radio-item-sidebar-option.selected {
  background-color: #d9d9d9;
}
.div-radio-item-sidebar-option:not(.selected) {
  background-color: #f5f5f5;
}
/*  Toggle Radio Item */
.toggle-div-radio-item {
  position: relative;
  display: flex;
  justify-content: space-between;
  background-color: var(--background-color-nav);
  padding: 0.25rem 0.5rem;
  color: var(--app-white);
}

.toggle-div-radio-item:hover {
  cursor: pointer;
}

.toggle-div-radio-item input[type="radio"] {
  display: none;
}

.toggle-div-radio-item input[type="radio"]:disabled {
  cursor: not-allowed;
}

.toggle-label-radio-item {
  position: relative;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--padding-025);
  transition: color 0.75s;
}

.toggle-label-radio-item.selected {
  color: var(--background-color-nav);
  background-color: var(--app-white);
}

.toggle-label-radio-item.selected::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--app-white);
  z-index: -1;
  transition: transform 0.75s ease;
}

.toggle-label-radio-item::after {
  transform: translateX(-5%);
  transition: transform 0.75s ease;
}

.toggle-label-radio-item.selected::after {
  transform: translateX(50);
}

.toggle-label-radio-item:not(.selected) {
  color: var(--app-white);
  background-color: transparent;
}
