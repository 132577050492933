/* CSS Properties For Vehicle Performance Page Specific Components */
@import "/src/pages/VehiclePerformancePage/VehiclePerformanceDataTab.css";

/* Main Container */
#vp-page-container-group1 {
  display: grid;
  grid-template-columns: 77% 23%;
  width: 100%;
  gap: 1rem;
}
#vp-page-container-group2 {
  display: grid;
  grid-template-columns: 23% 77%;
  width: 100%;
  margin-top: 1rem;
  gap: 1rem;
}
/* Scope Chart Card */
.vp-card-contianer-group-vp1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 99%;
}
.vp-card-contianer-group-vp2 {
  display: flex;
  flex-direction: row;
  width: 99%;
}

/* Emissions Per Vehicle & Idle Time Emissions */
.emissions-per-vehicle-title-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.vp-page-chart-container {
  display: grid;
  grid-template-columns: 5% 93% 2%;
  margin-top: 2rem;
  height: 15.25rem;
  width: 100%;
  align-items: center;
  margin-left: -1rem;
}
.vp-page-chart-container svg {
  height: 50px;
  width: 50px;
  cursor: pointer;
  color: #a4a3a3;
}

.vp-barchart-div {
  width: inherit;
  height: inherit;
}
.emissions-per-vehicle-right-title-group {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.emissions-per-vehicle-right-title-group:first-child {
  margin-bottom: 1rem;
}
.vp-button-filter-container-group {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}
.clear-button-filter-container,
.submit-button-filter-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.clear-button-filter-container button {
  background-color: var(--app-light-gray);
  border: none;
  color: var(--text-color-label);
  font-weight: 600;
  font-size: var(--font-vsmall);
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 0.5rem;
  width: 100px;
}
.submit-button-filter-container button {
  background-color: var(--app-blue);
  border: none;
  color: var(--app-white);
  font-weight: 600;
  font-size: var(--font-vsmall);
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 0.5rem;
  width: 100px;
}
/* Table */
.vp-data-table-container {
  display: flex;
  flex-direction: column;
  background-color: var(--app-white);
  max-height: 70vh;
  min-height: 70vh;
}
.vp-data-title-group {
  background-color: var(--app-white);
  color: var(--text-color-main);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-bottom: 1.5rem;
  width: 100%;
  align-items: center;
  display: grid;
  grid-template-columns: 20% 50% 30%;
}
.vp-data-title-group-right {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: var(--font-small);
}
.vp-data-title-group-right label {
  margin-left: 0.5rem;
}
.vp-title-icon-group {
  position: relative;
  align-items: center;
  justify-content: flex-end;
  width: 100px;
}
.vp-title-icon-group-svg {
  margin-left: 10px;
  cursor: pointer;
  background-color: var(--app-light-gray);
  padding: 10px;
  border-radius: 6px;
}
.vp-title-icon-dropdown {
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 100%;
  left: 0;
  width: 30%;
  max-height: 150px;
  box-shadow: 0px 4px 14px 0px #0000001a;
  border-radius: 6px;
  background-color: var(--app-white);
  z-index: 5;
}
.vp-title-icon-dropdown p {
  padding: 10px 0px;
  cursor: pointer;
  color: var(--text-color-label);
  font-size: var(--font-small);
  display: flex;
  align-items: center;
  justify-content: center;
}
.vp-title-icon-dropdown p:hover {
  background-color: aliceblue;
}
.vp-data-group-switch-carousel {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}
.vp-data-table {
  margin-top: -1rem;
  width: 100%;
  border-collapse: collapse;
  max-height: 80vh;
  overflow-y: scroll;
}

/* Vehicle Performance General Profile */
.vp-profile-container {
  display: grid;
  grid-template-columns: 20% 80%;
  gap: 1rem;
  height: inherit;
}

/* VP Profile Group Card */
.vehicle-group-container {
  display: flex;
  flex-direction: column;
  background-color: var(--app-white);
  padding: var(--padding-1);
  border-radius: 1rem;
  overflow-y: scroll;
  overflow-x: hidden;
  min-height: 70vh;
  max-height: 70vh;
}
.vp-profile-group-card {
  display: grid;
  grid-template-columns: 17% 25% 25% 17% 4%;
  background-color: var(--app-white);
  padding: var(--padding-1);
  border-radius: 1rem;
  box-shadow: 0px 4px 14px 0px #0000001a;
  height: fit-content;
  margin-bottom: 10px;
  gap: 1rem;
}
.vp-profile-group-card-1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--app-white);
  background-color: var(--text-color-label);
  border-radius: 6px;
}
.vp-profile-group-card-1 svg {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  color: var(--app-white);
}
.vp-profile-group-card-label {
  color: var(--app-white);
  font-weight: 600;
  line-height: normal;
  text-align: center;
  overflow-wrap: break-word;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}
.vp-profile-group-card-label p {
  margin-right: 0.5rem;
}
.vp-profile-group-card-2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.vp-profile-group-card-body-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin: 0.5rem;
  justify-content: flex-start;
}
.vp-profile-group-card-body-row-label {
  width: 85px;
}
.vp-profile-group-card-body-row-label-2 {
  width: 150px;
}
.vp-profile-group-card-body-row-value {
  font-weight: 600;
  color: var(--text-color-label);
}

.vp-profile-group-card-3 {
  height: 100px;
  display: block;
  margin: 0 auto;
  width: 100px;
}
.vp-profile-group-card-3 p {
  color: var(--text-color-main);
  font-weight: 600;
  line-height: normal;
  text-align: center;
  overflow-wrap: break-word;
}

.vp-profile-group-card-4 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--text-color-label);
}
.vp-profile-group-4 svg {
  height: 100px;
  width: 100px;
}

/* Vehicle Profile Page */
.vehicle-profile-page {
  display: grid;
  grid-template-columns: 31% 35% 31%;
  gap: 1rem;
  height: inherit;
}
.vehicle-profile-card-title {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 0.25rem;
  background-color: var(--text-color-label);
  color: var(--app-white);
  border-radius: 6px;
  min-height: 30px;
  font-size: var(--font-small-medium);
}

.vehicle-profile-card1,
.vehicle-profile-card3 {
  display: flex;
  flex-direction: column;
  background-color: var(--app-white);
  border-radius: 6px;
  box-shadow: 0px 4px 14px 0px #0000001a;
  height: inherit;
  overflow-y: hidden;
  overflow-x: hidden;
}
.vehicle-profile-card2 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.vehicle-profile-card2-top {
  display: flex;
  flex-direction: column;
  background-color: var(--app-white);
  border-radius: 6px;
  box-shadow: 0px 4px 14px 0px #0000001a;
  height: 65%;
}
.vehicle-profile-card2-bottom {
  display: flex;
  flex-direction: column;
  background-color: var(--app-white);
  border-radius: 6px;
  box-shadow: 0px 4px 14px 0px #0000001a;
  height: inherit;
}
.vehicle-profile-truck-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 100px;
  margin: 0 auto;
}
.vehicle-profile-card1-content {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 0.5rem;
  height: 100%;
}
.vehicle-profile-info-group1 {
  display: grid;
  grid-template-columns: 30% 45% 25%;
  align-items: center;
  border-radius: 6px;
  border: 1px solid var(--app-light-gray);
  margin-bottom: 0.25rem;
  gap: 2rem;
  font-size: var(--font-small);
}
.vehicle-profile-nav-settings {
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: center;
  width: 100%;
  text-decoration: underline;
  padding: 0.5rem 0rem;
  color: var(--app-blue);
  font-size: var(--font-small);
  cursor: pointer;
}
.vehicle-profile-info-group-label {
  font-weight: 500;
  align-items: center;
  border-right: 1px solid var(--app-light-gray);
  background-color: var(--app-light-gray);
  padding: 0.35rem;
  border-radius: 6px;
  text-align: center;
  font-size: var(--font-small);
}
.vehicle-profile-info-group-label svg {
  margin-right: 0.5rem;
}
.vehicle-profile-info-group-value {
  align-items: center;
  text-align: center;
  color: var(--text-color-main);
}
.vehicle-profile-info-group-lock {
  align-items: center;
  text-align: center;
  width: 20px;
  height: 20px;
  color: var(--text-color-main);
}

.vehicle-profile-drive-status-icon,
.vehicle-profile-drive-status-icon-inactive {
  align-items: center;
  padding: 0.25rem 0rem;
  border-radius: 6px;
  color: var(--app-white);
  width: 100%;
  text-align: center;
}
.vehicle-profile-drive-status-icon {
  background-color: var(--app-green);
}
.vehicle-profile-drive-status-icon-inactive {
  background-color: var(--app-red);
}
.vehicle-profile-card-3-chart {
  height: 100px;
  display: block;
  margin: 0 auto;
  width: 175px;
}
.vehicle-profile-card-3-chart-label {
  color: var(--text-color-label);
  font-style: normal;
  font-weight: var(--font-weight-reg);
  line-height: normal;
  text-align: center;
  line-height: 1.5rem;
  overflow-wrap: break-word;
}
.vehicle-profile-header-group {
  display: var(--header-display);
  flex-direction: var(--header-display-flex-direction);
  justify-content: var(--header-display-justify-content);
  align-items: var(--header-display-align-items);
  padding: var(--header-display-padding);
}
.vehicle-profile-header-group button {
  margin-left: 0.5rem;
}
.vehicle-profile-back-group {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: var(--text-color-label);
  font-size: var(--font-small);
}
.vehicle-profile-back-group p {
  margin-left: 0.5rem;
  cursor: pointer;
}
.back-arrow-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  cursor: pointer;
}
.vp-profile-title-group {
  background-color: var(--app-white);
  color: var(--text-color-main);
  font-size: var(--font-small-medium);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-bottom: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.vp-profile-icon-group {
  display: flex;
  align-items: center;
  width: 250px;
  justify-content: flex-end;
}
.vp-profile-group-switch-carousel {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
}

/* Mobile View */
@media screen and (max-width: 900px) {
  #vp-page-container-group1,
  #vp-page-container-group2,
  .vehicle-profile-page {
    display: flex;
    flex-direction: column;
  }
}

@media print {
  .vehicle-profile-nav-settings {
    display: none !important;
  }
}
