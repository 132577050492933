/* CSS Properties For Forgot Password Page */
/* Within Login Page */
.forgotpw-button-container li {
  list-style-type: none;
  padding: 10px 70px;
  border: 0px;
  border-radius: 6px;
  background-color: var(--app-white);
  font-size: var(--font-small-medium);
  cursor: pointer;
}
.forgotpw-button-container li a {
  color: var(--app-blue);
  cursor: pointer;
  text-decoration: underline;
}
.forgotpw-button-container li a:hover {
  text-decoration: none;
  color: var(--text-color-main);
}
/* Forgot Password Page */
#forgotpw-container {
  width: 70%;
  height: 82vh;
  overflow-y: scroll;
  margin: 2px auto;
  padding: 20px;
  border-radius: 6px;
  z-index: 1;
  background-color: var(--app-white);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
}
.forgotpw-content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.forgotpw-grid-container {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 1rem;
  width: 100%;
  margin: 1rem 0;
  align-items: center;
  transition: all 0.3s ease-in-out;
}

.forgotpw-center-group-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 75%;
  transition: all 0.3s ease-in-out;
}
.forgotpw-grid-container-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.forgotpw-content-container-title {
  color: var(--text-color-main);
  font-size: var(--font-large);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 1rem;
}
.forgotpw-content-container-subtitle {
  color: #333333;
  font-size: var(--font-medium);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 1rem;
}
.forgotpw-input-main-container {
  position: relative;
  justify-content: center;
  display: flex;
  flex-direction: column;
  width: 75%;
  margin: 5px;
}
.forgotpw-input-main-container p {
  color: var(--text-color-main);
  font-size: var(--font-small-medium);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0.5rem;
}
.forgotpw-input-element {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 6px;
  width: 100%;
  margin-bottom: 1rem;
}
.forgotpw-input-element::placeholder {
  color: #999;
}
.forgotpw-input-element:focus {
  border: 1px solid #ccc;
}
.forgotpw-input-element:hover {
  border-color: #666;
}
.forgotpw-button-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.forgotpw-button-container button,
.forgotpw-button-container-center button {
  padding: 10px 70px;
  border: 0px;
  border-radius: 6px;
  background-color: var(--text-color-main);
  color: var(--app-white);
  font-size: var(--font-small-medium);
  cursor: pointer;
}
.forgotpw-button-container button:disabled,
.forgotpw-submit-button-container button:disabled,
.forgotpw-button-container-center button:disabled {
  cursor: not-allowed;
}
.forgotpw-button-container button:disabled:hover::before {
  position: absolute;
  left: 31%;
  transform: translate(-50%);
  margin-top: 6rem;
  color: var(--app-white);
  background: rgba(10, 10, 7, 0.7);
  border-radius: 5px;
  padding: 10px;
  width: 200px;
  content: "Please enter your email";
  font-size: var(--font-small);
}
.forgotpw-button-container-center button:disabled:hover::before {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  margin-top: 6rem;
  color: var(--app-white);
  background: rgba(10, 10, 7, 0.7);
  border-radius: 5px;
  padding: 10px;
  width: 200px;
  content: "Please enter your email";
  font-size: var(--font-small);
}
.forgotpw-submit-button-container button {
  padding: 10px 70px;
  border: 0px;
  border-radius: 6px;
  background-color: var(--app-green);
  color: var(--app-white);
  font-size: var(--font-small-medium);
  cursor: pointer;
}
.forgotpw-submit-button-container button:disabled:hover::before {
  position: absolute;
  left: 70%;
  transform: translate(-50%);
  margin-top: 4rem;
  color: var(--app-white);
  background: rgba(10, 10, 7, 0.7);
  border-radius: 5px;
  padding: 10px;
  width: 200px;
  content: "Please enter valid credentials";
  font-size: var(--font-small);
}
/* ErrMsg */
.forgotpw-err-msg {
  color: #ff0000;
  margin-bottom: 1rem;
  margin-top: 0;
  text-align: center;
}
