/* CSS Properties For Login Page Specific Components */
#login-container {
  min-height: 100%;
  height: 100vh;
  background-color: var(--app-white);
}
/* Login Fields */
.login-content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4rem 2rem;
}
.login-content-container-title {
  color: var(--text-color-main);
  font-size: var(--font-large);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 1rem;
}
.login-content-container-subtitle {
  color: #333333;
  font-size: var(--font-medium);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 1rem;
}
.login-input-main-container {
  position: relative;
  justify-content: center;
  display: flex;
  flex-direction: column;
  width: 35%;
  margin: 5px;
}
.login-input-main-container p {
  color: var(--text-color-main);
  font-size: var(--font-small-medium);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0.5rem;
}
.login-input-element {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 6px;
  width: 100%;
  margin-bottom: 1rem;
}
.login-input-element::placeholder {
  color: #999;
}
.login-input-element:focus {
  border: 1px solid #ccc;
}
.login-input-element:hover {
  border-color: #666;
}
.login-button-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* padding-bottom: 1.25rem; */
}
.login-button-container button {
  padding: 10px 70px;
  border: 0px;
  border-radius: 6px;
  background-color: var(--app-green);
  color: var(--app-white);
  font-size: var(--font-small-medium);
  cursor: pointer;
}
.login-button-container button:disabled {
  cursor: not-allowed;
}
.login-button-container button:disabled:hover::before {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  margin-top: 6rem;
  color: var(--app-white);
  background: rgba(10, 10, 7, 0.7);
  border-radius: 5px;
  padding: 10px;
  width: 200px;
  content: "Please enter valid login fields";
  font-size: var(--font-small);
}

/* ErrMsg */
.login-err-msg {
  color: #ff0000;
  margin-bottom: 1rem;
  margin-top: 0;
  text-align: center;
}

.login-footer-image-container {
  background-image: url("/src/assets/colorFormat.png");
  background-repeat: repeat-x;
  background-position: right bottom;
  height: 100px;
  width: 100%;
  opacity: 0.5;
  position: fixed;
  bottom: 0;
}
