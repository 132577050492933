/* Scope 2 Manager CSS */

/* Scope 2 Manager */
#scope2-manager-container {
  margin: 0.5rem 0rem;
  width: 100%;
  display: grid;
  grid-template-columns: 50% 50%;
  height: inherit;
  gap: 1rem;
}
#scope2-manager-container-empty-state {
  margin-top: 1rem;
  width: 100%;
}
.scope2-container-group {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.scope2-manage-previous-sub {
  margin: 0.5rem 0rem;
  display: grid;
  grid-template-columns: 20% 80%;
  gap: 1rem;
  height: inherit;
}
.scope2-filter-container {
  display: flex;
  flex-direction: column;
  background-color: var(--app-white);
  padding: var(--padding-1);
  border-radius: 1rem;
  overflow: hidden;
  justify-content: space-between;
  min-height: 65vh;
  max-height: 65vh;
}
.scope2-filters-scroll-group {
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 58vh;
  max-height: 58vh;
}
.scope2-table-container {
  display: flex;
  flex-direction: column;
  background-color: var(--app-white);
  padding: var(--padding-1);
  border-radius: 1rem;
  max-height: 65vh;
  min-height: 65vh;
}
.scope2-data-table {
  width: 100%;
  border-collapse: collapse;
  max-height: 58vh;
  overflow-y: scroll;
}
.scope2-address-data-table {
  width: 100%;
  border-collapse: collapse;
  max-height: 50vh;
  overflow-y: scroll;
}
.scope2-button-filter-container-group {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}
.scope2-historical-table-container-group {
  margin: 1rem;
  margin-top: 1rem;
  height: 20rem;
  overflow-y: scroll;
}
.scope2-submit-entry-button button {
  margin-top: 1rem;
  position: relative;
}
.scope2-submit-entry-button button:disabled:hover::before {
  position: absolute;
  right: 13%;
  transform: translate(-50%);
  margin-top: -0.5rem;
  color: var(--app-white);
  background: rgba(10, 10, 7, 0.7);
  border-radius: 4px;
  padding: 9px;
  width: 300px;
  content: "Please fill out all required fields";
  font-size: var(--font-small);
  opacity: 0;
  animation: showMessage 0.3s forwards;
  animation-delay: 3s;
  animation-fill-mode: forwards;
}

/* Empty State */
.scope2-empty-state {
  background-color: var(--app-white);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 70vh;
  border-radius: 1rem;
}
.scope2-empty-state img {
  width: 10rem;
  margin-bottom: 1rem;
}
.scope2-empty-state-header {
  font-size: var(--font-medium-large);
  color: var(--text-color-main);
  margin-bottom: 1rem;
}
.scope2-empty-state p,
.scope2-partial-empty-state p {
  color: var(--text-color-label);
  margin-bottom: 1rem;
}
/* Partial Empty State */
.scope2-partial-empty-state {
  background-color: var(--app-white);
  display: flex;
  flex-direction: column;
  height: 70vh;
  border-radius: 1rem;
  padding: var(--padding-1);
}
.scope2-partial-empty-state-header {
  font-size: var(--font-medium-large);
  color: var(--text-color-main);
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
/* SCOPE 2 DELETE STATE */
.scope2-delete-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 30vh;
  color: var(--text-color-main);
}
.scope2-delete-container-location {
  font-size: var(--font-small-medium);
  /* color: var(--app-white); */
  /* background-color: var(--text-color-main); */
  padding: 0.5rem;
  border-radius: 0.5rem;
  font-weight: 600;
}
.scope2-edit-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--text-color-main);
}

/* Mobile View */
@media screen and (max-width: 900px) {
  #scope2-manager-container,
  .scope2-manage-previous-sub {
    display: flex;
    flex-direction: column;
  }
  .scope2-filter-container,
  .scope2-filters-scroll-group {
    min-height: fit-content;
    height: fit-content;
  }
}
