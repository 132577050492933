/* CSS Properties For Doughnut Component */
#doughnut-chart-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 700px;
  height: 700px;
  overflow: scroll;
}
.doughnut-chart {
  width: 700px;
  height: 700px;
}
