/* CSS Properties For CheckBox Component */

/* Primary */
.checkbox-item-container {
  display: flex;
  flex-direction: column;
  align-content: center;
}
.checkbox-item-option {
  cursor: pointer;
}
.checkbox-item-option input[type="checkbox"] {
  margin-right: 0.5rem;
}
.checkbox-item-option.selected {
  background-color: var(--app-blue);
  color: var(--app-white);
}

.checkbox-item-option-padding {
  padding: 10px 20px;
  cursor: pointer;
}
.checkbox-item-option-padding input[type="checkbox"] {
  margin-right: 0.5rem;
}
.checkbox-item-option-padding .selected {
  background-color: var(--app-blue);
  color: var(--app-white);
}
