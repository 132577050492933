/* CSS Properties For Report Page Specific Components */

/* Report Page */
.report-header-background-div {
  margin-bottom: 0.25rem;
}
.report-title-group {
  display: grid;
  grid-template-columns: 70% 30%;
  background-color: var(--background-color-nav);
  padding: 0.75rem 0.75rem 0.75rem 0.75rem;
}
.report-title-group p {
  color: var(--app-white);
  font-size: var(--font-medium-large);
  line-height: 1.75rem;
  align-items: center;
  width: fit-content;
}
.report-subtitle-group {
  display: grid;
  /* grid-template-columns: 70% 30%; */
  background-color: var(--app-white);
  align-items: center;
  padding-right: 1rem;
  padding-left: 1rem;
  padding-top: 0.75rem;
}
.report-subtitle-group p {
  color: var(--text-color-label);
  font-size: var(--font-medium);
  font-weight: 500;
  width: fit-content;
}
.report-subtitle-date-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.reports-select-timeframe-group {
  width: 40%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 1rem;
  justify-content: flex-end;
}
/* Download Reports Group */
.download-reports-group,
.download-reports-group-5 {
  display: grid;
  width: 98%;
  border-radius: 1rem;
  background-color: var(--app-white);
  padding: 1.5rem 1rem;
  margin-bottom: 0.5rem;
  align-items: center;
}
.download-reports-group {
  grid-template-columns: 25% 25% 25% 25%;
}
.download-reports-group-5 {
  grid-template-columns: 20% 20% 20% 20% 20%;
}
.download-reports-icon-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.download-reports-icon-group svg {
  margin-left: 10px;
}
.download-reports-loading-spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.3rem;
  width: 8rem;
}
.download-reports-loading-spinner-container svg {
  height: 2rem;
  width: 6rem;
}
.download-reports-additional-info-button {
  margin: auto;
  padding: 0.5rem;
  border-radius: 6px;
  background-color: var(--app-white);
  border: 1px solid var(--text-color-label);
  color: var(--text-color-label);
  cursor: pointer;
}

/* Reports Page - New Reports Title Group */
.report-page-container {
  display: grid;
  grid-template-columns: 17% 83%;
  gap: 1rem;
}
/* Sidebar */
.reports-sidebar-container {
  display: flex;
  flex-direction: column;
  background-color: var(--app-white);
  padding: var(--padding-1);
  border-radius: 1rem;
  overflow-y: hidden;
  overflow-x: hidden;
  justify-content: space-between;
  min-height: 75vh;
  max-height: 75vh;
}
.reports-container-group {
  display: flex;
  flex-direction: column;
  background-color: var(--app-white);
  border-radius: 1rem;
  max-height: 79vh;
  min-height: 79vh;
  overflow: hidden;
}
.reports-sidebar-label-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.select-dropdown-reports-main-container {
  position: relative;
  display: inline-block;
  width: 50%;
}
.select-dropdown-reports-date-container,
.select-dropdown-reports-date-container-disabled {
  width: 100%;
  position: relative;
  margin-left: 1rem;
}
.select-dropdown-reports-date-container-disabled {
  cursor: not-allowed;
  position: relative;
}
.select-dropdown-reports-date-container-disabled::after {
  content: "Please select a year";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background: #333;
  color: #fff;
  padding: 5px;
  border-radius: 3px;
  white-space: nowrap;
  font-size: var(--font-vsmall);
  z-index: 10;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  opacity: 0;
  visibility: hidden;
  transition: visibility 5s ease; /* Adjust the delay here */
  cursor: not-allowed;
}
.select-dropdown-reports-date-container-disabled:hover::after {
  opacity: 1;
  visibility: visible;
}
/* Custom Reports*/
.custom-reports-checkbox-grid-group {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  margin-top: 2rem;
}

/* Standard Reports */
.standard-reports-container-group {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  padding: 0rem 0rem 1rem 1rem;
}
.report-question-group-container {
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
}
.report-question-group-container-question {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.15rem;
  font-weight: 600;
  line-height: 1.75rem;
}
.energy-dropdown-container-group {
  display: grid;
  grid-template-columns: 60% 40%;
  margin-top: 1rem;
}
.reporting-intro-selected-date-group {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.reporting-flex-group {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.reporting-intro-selected-date-group p {
  margin-left: 1rem;
  align-items: center;
  line-height: 2rem;
}
.report-new-entry-container {
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  border: 1px solid var(--text-color-label);
  display: inline-block;
  align-items: center;
  justify-content: center;
  color: var(--text-color-label);
  cursor: pointer;
  position: relative;
  margin-left: 0.5rem;
}
.report-new-entry-container p {
  display: flex;
  align-items: center;
  justify-content: center;
}
.report-delete-entry-container {
  display: inline-block;
  align-items: center;
  justify-content: center;
  color: var(--text-color-label);
  cursor: pointer;
  position: relative;
  margin-left: 0.5rem;
}

.company-name-title-reports {
  color: var(--text-color-label);
  font-size: var(--font-small-medium);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

/* Mobile View */
@media screen and (max-width: 900px) {
  .report-page-container {
    display: flex;
    flex-direction: column;
  }
  .reports-sidebar-container {
    min-height: fit-content;
    max-height: fit-content;
  }
}
