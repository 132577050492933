/* CSS Properties For Input Component */

/* Primary */
.input-main-container {
  position: relative;
  display: inline-block;
}
.input-element {
  padding: 0.35rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: var(--font-small);
}
.input-element::placeholder {
  color: #999;
  font-size: var(--font-small);
  text-decoration: italic;
}
.input-element:focus {
  border-color: #666;
  outline: none;
}
.input-element:hover {
  border-color: #666;
}
.input-password-icon-container {
  position: absolute;
  top: 40%;
  right: 0.5rem;
  transform: translateY(-50%);
}
.input-password-icon {
  cursor: pointer;
  height: 1.25rem;
  width: 1.25rem;
}
/* Input Report Element */
.input-report-element {
  padding: 0.35rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: var(--font-small);
}
.input-report-element::placeholder {
  color: #999;
  font-size: var(--font-small);
  text-decoration: italic;
}
.input-report-element:focus {
  border-color: #666;
  outline: none;
}
.input-report-element:hover {
  border-color: #666;
}
.input-report-element:placeholder-shown {
  border: 1px solid red; /* Red border only if the input is empty */
}
