/* CSS Properties For Navigation Component */
/* Main Container */
#navigation-bar-container {
  position: fixed;
  height: 3.5rem;
  width: 100%;
  display: block;
  background-color: var(--background-color-nav);
  display: grid;
  grid-template-columns: 70% 30%;
  top: 0;
  z-index: 500;
}
/* Text Link & Logo Navigation */
#navigation-link-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  height: 4rem;
  margin-left: 0.5rem;
}
#navigation-link-container img {
  align-items: center;
  width: 8rem;
  cursor: pointer;
}
#navigation-link-container li,
.sign-out-link li {
  list-style-type: none;
  color: var(--app-white);
  font-size: var(--font-small-medium);
  font-weight: 500;
  line-height: 21px;
}
#navigation-link-container li a,
.sign-out-link {
  text-decoration: none;
  color: #c7cacb;
}
#navigation-link-container li a:hover,
.sign-out-link:hover {
  color: var(--app-white);
  cursor: pointer;
}
/* Icon Link Navigation */
#navigation-icon-link-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0 1rem;
  height: 4rem;
  margin-left: 0.5rem;
  gap: 3rem;
}
#navigation-icon-link-container img {
  align-items: center;
  width: 23px;
  height: 23px;
  cursor: pointer;
}
#navigation-icon-link-container li {
  list-style-type: none;
  color: var(--app-white);
  font-size: var(--font-small-medium);
  font-weight: 500;
  line-height: 21px;
}
/* Active Navigation Class Underlining Text & Icon */
.active-navigation-icon-underline,
.active-navigation-text-underline {
  border-bottom: 2px solid transparent; /* Start with a transparent underline */
  position: relative;
}
.active-navigation-icon-underline::after,
.active-navigation-text-underline::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  border-bottom: 5px solid #19b24c;
  margin: 0px -0.75em;
}
.active-navigation-text-underline::after {
  bottom: -0.75em;
}
#navigation-link-container .active-navigation-text-underline.active {
  color: var(--app-white);
}
.active-navigation-icon-underline::after {
  bottom: -0.6em;
}

/* Mobile View */
@media screen and (max-width: 900px) {
  #navigation-link-container img {
    align-items: center;
    width: 5rem;
    cursor: pointer;
  }
  #navigation-icon-link-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 0 1rem;
    height: 4rem;
    color: var(--app-white);
  }
  #navigation-icon-link-container svg {
    align-items: center;
    width: 1.25rem;
    height: 1.25rem;
    cursor: pointer;
    color: var(--app-white);
  }
  .navigation-menu-folder {
    position: absolute;
    display: flex;
    top: 3.5rem;
    left: 0;
    width: 100%;
    min-height: 100vh;
    max-height: 100vh;
    height: 100vh;
    justify-content: flex-start;
    overflow-y: scroll;
    overflow-x: hidden;
    z-index: 1000;
    background-color: var(--background-color-nav);
    opacity: 95%;
  }
  .navigation-menu-folder-content {
    position: relative;
    display: flex;
    width: 100%;
    flex-grow: 1;
    flex-shrink: 0;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    color: var(--app-white);
  }
  .navigation-menu-folder-content li {
    list-style-type: none;
    color: var(--app-white);
    font-size: var(--font-medium);
    font-weight: 500;
    line-height: 21px;
    margin-bottom: 1rem;
  }
  .navigation-menu-folder-content li a {
    list-style-type: none;
    color: var(--app-white);
    text-decoration: none;
    font-size: var(--font-medium);
    font-weight: 500;
    line-height: 21px;
  }
  .active-navigation-icon-underline::after,
  .active-navigation-text-underline::after {
    border-bottom: none;
  }
  .sign-out-link {
    text-decoration: none;
    color: var(--app-white);
    font-size: var(--font-medium);
  }
}
