/* CSS Properties For Vehicle Performance Data Tab Specific Components */

/* Vehicle Performance Data Page */
.vp-data-container-page {
  display: grid;
  grid-template-columns: 17% 83%;
  height: inherit;
  gap: 0.75rem;
  background-color: var(--app-white);
  border-radius: 6px;
  padding: var(--padding-1);
}
/* Sidebar */
.vp-sidebar-container {
  display: flex;
  flex-direction: column;
  background-color: var(--app-white);
  overflow: hidden;
  justify-content: space-between;
  min-height: 70vh;
  max-height: 70vh;
}
.vp-sidebar-header-group {
  display: grid;
  grid-template-columns: 80% 20%;
  width: 100%;
  gap: 0.75rem;
  margin-bottom: 0.75rem;
}
.vp-sidebar-header-group img {
  width: 18px;
  height: 18px;
  object-fit: contain;
}
.vp-sidebar-header-group p {
  font-weight: 600;
  font-size: var(--font-small);
}
.vp-sidebar-nested-group {
  margin: 10px;
}

.vp-sidebar-accordion-group {
  display: grid;
  grid-template-columns: 83% 17%;
  align-items: center;
  background-color: var(--app-light-gray);
  margin-bottom: 0.1rem;
  height: inherit;
  padding: var(--padding-025);
}
.vp-sidebar-accordion-group p {
  font-weight: 600;
  font-size: var(--font-small);
}
.vp-sidebar-accordion-group svg {
  width: 16px;
  height: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.vp-sidebar-range-slider-group {
  display: flex;
  flex-direction: column;
  height: inherit;
  font-size: var(--font-vsmall);
  margin: 0.5rem;
}

/* Vehicle Performance Data Outdated */
/* Filters */
.vp-filters-scroll-group {
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 62vh;
  max-height: 62vh;
}
/* Mobile View */
@media screen and (max-width: 900px) {
  .vp-data-container-page {
    display: flex;
    flex-direction: column;
  }
  .vp-sidebar-container,
  .vp-filters-scroll-group {
    width: 100%;
    min-height: fit-content;
    max-height: fit-content;
  }
}
