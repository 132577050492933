/* CSS Properties For Select Dropdown Component */

/* Primary */
.select-dropdown-main-container {
  position: relative;
  display: inline-block;
  font-size: var(--font-small);
  width: inherit;
  height: inherit;
}
.select-dropdown-main-container-100 {
  position: relative;
  display: inline-block;
  font-size: var(--font-small);
  width: 100%;
}
.select-dropdown-header-container-disabled {
  cursor: not-allowed;
}
.select-dropdown-header-container {
  cursor: pointer;
}
.select-dropdown-header-container,
.select-dropdown-header-container-disabled {
  padding: 0.25rem 0.5rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: var(--font-small);
  align-items: center;
  display: flex;
  justify-content: space-between;
  background-color: var(--app-white);
  width: 97%;
}
.select-dropdown-header-container:hover {
  border-color: #666;
}
.select-dropdown-header-container.open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  display: flex;
}
.select-dropdown-options-container {
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 150px;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 5px 5px;
  background-color: var(--app-white);
  overflow-y: scroll;
  z-index: 5;
}
.select-dropdown-option {
  padding: 10px;
  cursor: pointer;
  color: #666;
}
.select-dropdown-option:hover {
  background-color: #ccc;
  color: var(--app-white);
}
.select-dropdown-option.selected {
  background-color: #666;
  color: var(--app-white);
}
.multi-select-placeholder-container {
  display: flex;
  flex-direction: column;
  width: 75%;
}
.multi-select-placeholder-value {
  font-size: var(--font-small);
  color: #666;
  background-color: var(--app-light-gray);
  padding: 5px;
}
