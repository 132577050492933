/* CSS Properties For SearchBar Component */
.search-bar-main-container-100 {
  position: relative;
  display: inline-block;
  font-size: var(--font-small);
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0px 4px 14px 0px #0000001a;
  border-radius: 6px;
}

.search-bar-header-container {
  display: var(--header-display);
  flex-direction: var(--header-display-flex-direction);
  justify-content: var(--header-display-justify-content);
  align-items: var(--header-display-align-items);
  padding: 0.25rem 0.5rem;
  cursor: pointer;
}

.search-bar-header-container.open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  display: flex;
}

.search-bar-input {
  position: relative;
  display: inline-block;
  font-size: var(--font-small);
  width: 100%;
  height: inherit;
  border: 0px;
  background-color: inherit;
}
.search-bar-input.disabled {
  cursor: not-allowed;
}
.search-bar-input:focus {
  outline: none;
}

.search-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--padding-025);
  background-color: var(--app-white);
  cursor: pointer;
}

.search-bar-options-container {
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 150px;
  box-shadow: 0px 4px 14px 0px #0000001a;
  border-radius: 6px;
  background-color: var(--app-white);
  overflow-y: scroll;
  z-index: 5;
}

.search-bar-option {
  padding: 7px 15px;
  cursor: pointer;
  color: var(--text-color-label);
  border-radius: 6px;
}

.search-bar-option:hover {
  background-color: aliceblue;
  color: var(--text-color-label);
}

.search-bar-option.selected {
  background-color: var(--text-color-label);
  color: var(--app-white);
}

.selected-options-container {
  margin-top: 10px;
  max-height: 100px;
  overflow-y: scroll;
}

.selected-option {
  display: flex;
  align-items: center;
  font-size: var(--font-small);
  padding: 5px 10px;
}

.selected-option input[type="checkbox"] {
  margin-right: 10px;
}
