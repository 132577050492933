/* CSS Properties For Help Page */
.help-page-background-container {
  /* position: fixed; */
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-position: right bottom;
  opacity: 0.1;
  z-index: -1;
}
.help-page-main-container-content {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 90vw;
  height: 70vh;
  margin: 10px auto;
  z-index: 0;
}

.hubspot-form-middle-help {
  width: 80%;
  height: 80vh;
  overflow-y: scroll;
  margin: 2px auto;
  padding: 20px;
  border-radius: 6px;
  z-index: 1;
  background-color: var(--app-white);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
}
