/* CSS Properties For Accordion Component */

/* Primary Accordion Class */
.accordion-section-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: var(--app-light-gray);
  margin-bottom: 0.25rem;
}
.accordion-section-group p {
  font-weight: 600;
  padding: var(--padding-1);
}
.accordion-icon {
  height: 1.5rem;
  width: 1.5rem;
  margin-right: 1rem;
}
.accordion-icon:hover {
  cursor: pointer;
}
.accordion-right-section-group {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
}
