/* CSS Properties For DatePicker Component */

/* Primary DatePicker */
.primary-datepicker {
  border: 1px solid #ccc;
  border-radius: 6px;
  padding: 5px;
  font-weight: var(--font-weight-reg);
  width: 100%;
}
.primary-datepicker::-webkit-calendar-picker-indicator {
  cursor: pointer;
}
.primary-datepicker:hover {
  border-color: #666;
  cursor: pointer;
}

/* Disabled DatePicker */
.primary-datepicker.disabled {
  cursor: not-allowed;
  position: relative;
}

.primary-datepicker.disabled:hover::after {
  content: "Select a start date";
  position: absolute;
  top: 00px;
  left: 0;
  background: #333;
  color: #fff;
  padding: 5px;
  border-radius: 3px;
  white-space: nowrap;
  font-size: var(--font-vsmall);
  z-index: 100;
}

.date-range-group {
  position: relative;
  display: inline-block;
  margin-left: 1rem;
  width: 40%;
}

.date-range-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
/* Secondary Date Picker (no-defined width) */
.secondary-datepicker {
  border: 1px solid #ccc;
  border-radius: 6px;
  padding: 5px;
  font-weight: var(--font-weight-reg);
}
.secondary-datepicker::-webkit-calendar-picker-indicator {
  cursor: pointer;
}
.secondary-datepicker:hover {
  border-color: #666;
  cursor: pointer;
}

/* Disabled DatePicker */
.secondary-datepicker.disabled {
  cursor: not-allowed;
  position: relative;
}

.secondary-datepicker.disabled:hover::after {
  content: "Select a start date";
  position: absolute;
  top: 00px;
  left: 0;
  background: #333;
  color: #fff;
  padding: 5px;
  border-radius: 3px;
  white-space: nowrap;
  font-size: var(--font-vsmall);
  z-index: 100;
}
