/* CSS Properties For Vehicle CSV Settings Page Specific Components */

/* Vehicle Settings */
.vp-settings-container {
  height: inherit;
}
.vp-settings-table {
  width: 100%;
  border-collapse: collapse;
  max-height: 80vh;
  overflow-y: scroll;
  margin-top: 1rem;
}
.vp-settings-modal-table {
  width: 100%;
  border-collapse: collapse;
  max-height: 35vh;
  overflow-y: scroll;
  margin-top: 1rem;
}
.settings-update-vehicle-modal-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}
.settings-singular-update-vehicle-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
  /* justify-content: space-between; */
  /* min-height: 55vh; */
  width: 100%;
}
.settings-update-vehicle-fields-group-pair {
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 1rem;
  margin-bottom: 0.25rem;
  width: 100%;
  background-color: #f5f5f5f5;
  padding: 0.5rem;
  border-radius: 6px;
}
.settings-update-vehicle-fields-group {
  width: 100%;
}
.settings-update-vehicle-fields {
  display: grid;
  grid-template-columns: 30% 60%;
  gap: 1rem;
  margin-top: 0.75rem;
  width: 100%;
  align-items: center;
}
.settings-update-vehicle-fields p {
  margin: 0;
  align-items: center;
  font-size: var(--font-vsmall);
}
.settings-update-vehicle-fields input {
  width: 98%;
}
.setting-vehicle-warning {
  color: var(--app-red);
  font-size: var(--font-tiny);
  margin: 0.75rem;
  text-align: center;
}

.settings-update-vehicle-button-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 0.75rem;
}

.search-clear-button-container {
  display: grid;
  grid-template-columns: 70% 15%;
  align-items: center;
  justify-content: space-around;
}
.search-clear-button-container button {
  background-color: var(--app-light-gray);
  border: none;
  color: var(--text-color-label);
  font-weight: 600;
  font-size: var(--font-vsmall);
  cursor: pointer;
  padding: var(--padding-1);
  border-radius: 0.5rem;
}
.settings-bulk-header-container {
  display: grid;
  grid-template-columns: 25% 50% 25%;
  align-items: center;
  justify-content: space-around;
  margin-top: 6px;
}

/* VEHICLE VIN DECODER  */
#vin-decoder-page-container {
  display: grid;
  grid-template-columns: 10% 90%;
  height: 80vh;
  border-radius: 6px;
  padding: var(--padding-025);
  gap: 2rem;
}
/* VIN DECODER SIDEBAR */
.vin-decoder-sidebar {
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
  padding: 0.1rem;
  border-radius: 6px;
  font-size: var(--font-small);
}
.vin-decoder-sidebar-subgroup {
  display: grid;
  grid-template-columns: 10% 90%;
  align-items: center;
  margin: 1rem 0;
  width: 100%;
  padding: 8px;
}
.vin-decoder-sidebar-subgroup p {
  margin-left: 1.5rem;
}
.vin-decoder-sidebar-subgroup-comp,
.vin-decoder-sidebar-subgroup-null {
  background-color: #ffffff;
  border-radius: 6px;
  box-shadow: 0px 4px 14px 0px #0000001a;
}
.vin-decoder-sidebar-subgroup-ip {
  background-color: #f5f5f5;
  border-radius: 6px;
}
.vin-decoder-sidebar-button {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 1rem 0;
}
.vin-decoder-sidebar-button button {
  display: flex;
  justify-content: center;
}
.vin-decoder-sidebar-button button svg {
  margin-right: 0.5rem;
}
#vin-decoder-page-content {
  width: 95%;
  padding: var(--padding-025);
  border-radius: 6px;
  font-size: var(--font-small);
}
/* VIN DOWNLOAD FILE PAGE */
.vin-decoder-upload-file-page-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.vin-decoder-download-template-group {
  display: grid;
  grid-template-columns: 70% 30%;
  gap: 0.5rem;
  align-items: center;
  width: 60%;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  background-color: var(--app-light-gray);
  padding: 0.75rem;
  border-radius: 6px;
}
.vin-decoder-download-template-header-container {
  display: flex;
  flex-direction: column;
}
.vin-decoder-download-template-header-container p {
  font-size: var(--font-small);
  color: var(--text-color-label);
}
.vin-decoder-download-template-header-container svg {
  height: 27px;
  width: 27px;
  color: var(--app-green);
}
.vin-decoder-download-group-header {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.vin-decoder-download-template-header-container
  .vin-decoder-download-group-header
  p {
  margin-left: 1rem;
}
/* VIN UPLOAD FILE PAGE */
.vin-decoder-upload-csv-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60%;
  padding: var(--padding-1);
  border: 1px dashed var(--app-blue);
  border-radius: 12px;
  background-color: rgba(243, 247, 254, 0.433);
  margin-bottom: 0.5rem;
}
.vin-decoder-upload-csv-container svg {
  height: 50px;
  width: 50px;
  color: var(--app-blue);
}
.vin-decoder-upload-csv-container p {
  margin-top: 1rem;
  color: var(--app-blue);
  font-weight: 600;
  font-size: var(--font-medium);
}
.vin-decoder-upload-csv-container input[type="file"] {
  display: none;
}
.vin-decoder-upload-message-group {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: var(--text-color-main);
}
.vin-decoder-upload-message-group div {
  margin: 1rem;
}

.vin-decoder-selected-file {
  display: none;
}
.vin-decoder-selected-file.file-selected {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 50%;
  padding: var(--padding-1);
  border-radius: 6px;
  background-color: #00a74d43;
  margin-bottom: 0.5rem;
}
.vin-decoder-selected-file.file-selected svg {
  margin-right: 1rem;
}
.vin-decoder-selected-file.file-not-selected {
  display: none;
}
.vin-decoder-selected-file-note {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 60%;
  padding: var(--padding-025);
  margin-bottom: 0.5rem;
  font-size: var(--font-vsmall);
  color: var(--text-color-label);
}
/* VIN CHECK INITIAL UPLOAD PAGE PAGE */
.vin-decoder-failed-entries-group,
.vin-decoder-success-entries-group {
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  margin: 0.5rem 0rem;
}
.vin-decoder-failed-entries-group {
  border: 1px solid rgba(255, 2, 2, 0.293);
}
.vin-decoder-success-entries-group {
  border: 1px solid rgba(0, 167, 77, 0.293);
}
.vin-decoder-failed-entries-subgroup,
.vin-decoder-success-entries-subgroup {
  display: flex;
  flex-direction: column;
  padding: var(--padding-025);
  margin-bottom: 0.25rem;
}
.vin-decoder-failed-entries-subgroup {
  background-color: rgba(255, 2, 2, 0.293);
}
.vin-decoder-success-entries-subgroup {
  background-color: rgba(0, 167, 77, 0.293);
}
.vin-decoder-failed-entries-subgroup-content {
  display: flex;
  flex-direction: column;
  padding: var(--padding-025);
  margin-bottom: 0.2rem;
  width: 100%;
}
.vin-decoder-success-entries-subgroup-content {
  display: flex;
  flex-direction: column;
  padding: var(--padding-025);
  margin-bottom: 0.25rem;
}
.vin-decoder-failed-entries-group-header,
.vin-decoder-success-entries-group-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: var(--font-small-medium);
}

.vin-decoder-failed-entries-group-header svg,
.vin-decoder-success-entries-group-header svg {
  height: 30px;
  width: 30px;
  margin-right: 0.25rem;
}
.vin-decoder-failed-entries-group-header svg {
  color: var(--app-red);
}
.vin-decoder-failed-entries-info-content {
  display: grid;
  grid-template-columns: 3% 5% 15% 72%;
  width: 100%;
  color: var(--text-color-label);
  margin: 0.25rem;
}
.vin-decoder-success-entries-group-header svg {
  color: var(--app-green);
}
.vin-decoder-success-entries-preview-table-header {
  display: grid;
  grid-template-columns: 87% 13%;
  width: 100%;
  align-items: center;
}
.vin-decoder-success-entries-preview-table {
  width: 100%;
  border-collapse: collapse;
  max-height: 50vh;
  overflow-y: scroll;
  margin-top: 1rem;
}
.vin-decoder-failed-entries-edit-container {
  display: grid;
  grid-template-columns: 55% 45%;
  width: 100%;
  margin-top: 0.25rem;
}
.vin-decoder-failed-entries-edit-container-11 {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-top: 0.25rem;
}
.vin-decoder-failed-entries-container {
  margin-top: 0.25rem;
}
.vin-decoder-failed-entries-container-header-group {
  max-height: 50vh;
  width: 100%;
  align-items: center;
  background-color: var(--app-light-gray);
  color: var(--text-color-main);
  padding: var(--padding-025);
  border-radius: 6px 6px 0px 0px;
  font-weight: 600;
}
.vin-decoder-container-table-group {
  overflow-y: scroll;
  max-height: 35vh;
  padding: var(--padding-025);
  width: 100%;
  background-color: var(--app-light-gray);
}
.vin-decoder-failed-entries-table-group,
.vin-decoder-failed-entries-table-group-white {
  display: grid;
  grid-template-columns: 20% 40% 30% 10%;
  align-items: center;
}
.vin-decoder-failed-entries-table-group {
  background-color: var(--app-light-gray);
}
.vin-decoder-failed-entries-table-group-white {
  background-color: #ffffff;
}
.vin-decoder-failed-entries-table-group div,
.vin-decoder-failed-entries-table-group-white div {
  margin: 0.1rem;
}

.vin-decoder-svg {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 50%;
}
.vin-decoder-svg:hover {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--app-red);
}
.vin-decoder-svg-discard {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 50%;
  color: var(--app-red);
}
.vin-decoder-svg-disabled {
  cursor: not-allowed;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--app-light-gray);
  display: none;
}
.vin-decoder-vehicle-duplicated {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(255, 2, 2, 0.293);
  border-radius: 6px;
  padding: var(--padding-025);
}
.vin-decoder-failed-entries-container-footer-group {
  max-height: 50vh;
  width: 100%;
  align-items: center;
  background-color: var(--app-light-gray);
  padding: var(--padding-025);
  border-radius: 0px 0px 6px 6px;
}

.vehicle-csv-check-group {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}
.vehicle-csv-check,
.vehicle-csv-x,
.vehicle-csv-x-rej,
.vehicle-csv-check-app {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 6px;
  cursor: pointer;
}
.vehicle-csv-check svg,
.vehicle-csv-x svg,
.vehicle-csv-x-rej svg,
.vehicle-csv-check-app svg {
  height: 30px;
  width: 30px;
  border-radius: 6px;
}
.vehicle-csv-check svg,
.vehicle-csv-x svg {
  color: var(--text-color-label);
  border: 1px solid var(--text-color-label);
}
.vehicle-csv-x-rej svg {
  color: var(--app-white);
  background-color: var(--app-red);
}
.vehicle-csv-check-app svg {
  color: var(--app-white);
  background-color: var(--app-green);
}
/* Vin Decoder Import */
.vin-decoder-import-header-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: var(--padding-025);
  margin-bottom: 0.25rem;
}
.vin-decoder-import-warning-msg {
  display: flex;
  background-color: bisque;
  border-radius: 6px;
  padding: var(--padding-025);
}
.vin-decoder-import-success-msg {
  display: flex;
  background-color: rgba(0, 167, 77, 0.293);
  border-radius: 6px;
  padding: var(--padding-025);
}
.vin-decoder-import-header-group label {
  margin-right: 0.25rem;
}
.vin-decoder-import-container-group {
  border-radius: 6px;
  width: 100%;
  background-color: var(--app-light-gray);
  padding: 0.5rem;
  text-align: center;
  margin: 0.5rem;
}
.vin-decoder-import-container-table-group svg {
  cursor: pointer;
}
.vin-decoder-import-container-table-group {
  overflow-y: scroll;
  max-height: 40vh;
  padding: var(--padding-025);
  width: 100%;
  background-color: var(--app-light-gray);
}
.vin-decoder-import-entries-table-group,
.vin-decoder-import-entries-table-group-white {
  display: grid;
  grid-template-columns: 5% 13% 10% 12% 10% 10% 10% 10% 10% 7% 3%;
  align-items: center;
  padding: 0.75rem;
  font-size: var(--font-vsmall);
}
.vin-decoder-import-entries-table-group {
  background-color: var(--app-light-gray);
}
.vin-decoder-import-entries-table-group-white {
  background-color: #ffffff;
}
.vin-decoder-import-entries-table-group div,
.vin-decoder-import-entries-table-group-white div {
  margin: 0.1rem;
}
.vin-decoder-import-entries-null-item {
  background-color: rgba(255, 2, 2, 0.293);
  padding: 0.05rem;
}
