/* CSS Properties For Switch Carousel Component */

/* Primary */
.switch-carousel-arrow {
  width: 18px;
  height: 18px;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.25);
  align-items: center;
  display: flex;
  justify-content: center;
  cursor: pointer;
}
.switch-carousel-arrow svg {
  width: 14px;
  height: 14px;
}
.switch-carousel-value {
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
}

/* Primary Group Used */
.group-switch-carousel {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1.5rem;
  align-items: flex-end;
}
.group-switch-carousel-value {
  color: var(--app-black);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.group-switch-carousel-no-margin {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}
