/* CSS Properties For Text Area Component */

/* Primary */
.text-area-main-container {
  position: relative;
  display: inline-block;
}
.text-area-element {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: var(--font-small-medium);
  min-width: 90%;
  max-width: 100%;
  height: 200px;
}
.text-area-element::placeholder {
  color: #999;
}
.text-area-element:focus {
  border-color: #3498db;
  outline: none;
}
.text-area-element:hover {
  border-color: #666;
}
.max-char-count-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: var(--padding-025);
}
.max-char-count-container-red {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: var(--padding-025);
  color: #ff1f2e;
}
.text-area-report-element {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: var(--font-small-medium);
  min-width: 90%;
  max-width: 100%;
  height: 200px;
}
.text-area-report-element::placeholder {
  color: #999;
}
.text-area-report-element:focus {
  border-color: #3498db;
  outline: none;
}
.text-area-report-element:hover {
  border-color: #666;
}
.text-area-report-element:placeholder-shown {
  border: 1px solid red; /* Red border only if the input is empty */
}
