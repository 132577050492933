/* CSS Properties For Metric Tile Group Simple Component */

/* Primary */
.metric-tile-group-simple-container {
  border-radius: 16px;
  overflow: hidden;
  width: inherit;
  height: 12rem;
  position: relative;
  background-color: var(--app-white);
  padding: var(--padding-2);
  align-items: center;
}
.metric-tile-group-simple-title-group {
  display: flex;
  gap: 2rem;
  justify-content: center;
}
.metric-tile-group-simple-value {
  display: flex;
  font-size: var(--font-xxlarge);
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin-top: 2.25rem;
  justify-content: center;
  color: var(--text-color-main);
}

.metric-title-group-simple-label {
  color: var(--text-color-label);
  font-size: var(--font-small-medium);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 0.5rem;
  display: flex;
  justify-content: center;
}
.metric-tile-group-simple-container-no-carbon {
  border-radius: 16px;
  overflow: hidden;
  width: inherit;
  height: 20rem;
  position: relative;
  background-color: var(--app-white);
  padding: var(--padding-2);
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* Mobile View */
@media screen and (max-width: 900px) {
  .metric-tile-group-simple-container {
    height: fit-content;
    width: 90%;
  }
}
