/* CSS Styling for Sidebar Component */
.sidebar-component {
  background-color: var(--app-white);
  border-radius: 12px 0 0 12px;
  box-shadow: 0 4px 21px rgba(0, 0, 0, 0.09);
  height: 100%;
  overflow-y: scroll;
  padding: 10px 15px;
  position: fixed;
  right: 0;
  top: 110px;
  width: 20%;
  z-index: 1000;
}
.sidebar-class {
  position: fixed;
  left: 0%;
  top: 7.7vh;
  right: 0;
  bottom: 0;
  z-index: 999;
}
.sidebar-div {
  position: inherit;
}
