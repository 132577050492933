/* CSS Properties For Snackbar Component */

/* Primary */
.custom-snackbar {
  position: fixed;
  bottom: 20px;
  left: 20px;
  width: 320px;
  z-index: 9999;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  font-size: var(--font-vsmall);
}
.custom-snackbar.open {
  opacity: 1;
  visibility: visible;
}
.alert {
  display: flex;
  align-items: center;
  padding: 16px;
  background-color: var(--app-white);
  border-radius: 4px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
}
.alert button {
  color: var(--app-white);
}
.alert.success {
  background-color: var(--app-green);
  color: var(--app-white);
}
.alert.error {
  background-color: #f44336;
  color: var(--app-white);
}
.alert.warning {
  background-color: #f4a536;
  color: var(--app-white);
}
.close {
  margin-left: auto;
  border: none;
  background-color: transparent;
  color: #555;
  cursor: pointer;
}
