/* CSS Properties For Signup Page Specific Components */

#signup-container {
  width: 70%;
  height: 82vh;
  overflow-y: scroll;
  margin: 2px auto;
  padding: 20px;
  border-radius: 6px;
  z-index: 1;
  background-color: var(--app-white);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  overflow-x: hidden;
}
.signup-grid-container {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 1rem;
  width: 100%;
  margin: 1rem 0;
  align-items: center;
  transition: all 0.3s ease-in-out;
}
.signup-grid-container-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: auto;
}
/* Signup Fields */
.signup-header-main-container {
  display: var(--header-display);
  flex-direction: var(--header-display-flex-direction);
  justify-content: var(--header-display-justify-content);
  align-items: var(--header-display-align-items);
  padding: var(--header-display-padding);
}
.signup-header-main-container img {
  width: 175px;
  max-width: 175px;
}
.signup-header-main-container button {
  padding: 10px 20px;
  border: 0px solid #ccc;
  border-radius: 6px;
  background-color: var(--background-color-nav);
  color: var(--app-white);
  font-size: var(--font-small-medium);
  cursor: pointer;
}
.redirect-login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.redirect-login-container p {
  color: #6c6c6c;
  font-size: var(--font-small-medium);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 1rem;
}
.signup-content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0rem 2rem;
}
.signup-content-container-title {
  color: var(--text-color-main);
  font-size: var(--font-large);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 1rem;
}
.signup-content-container-subtitle {
  color: #333333;
  font-size: var(--font-medium);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 1rem;
}
/* Input Container */
.signup-input-main-container p {
  color: var(--text-color-main);
  font-size: var(--font-small-medium);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0.5rem;
}
.signup-input-main-container {
  position: relative;
  justify-content: center;
  display: flex;
  flex-direction: column;
  width: 75%;
  margin: 5px;
}
.signup-input-element {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 6px;
  width: 100%;
  margin-bottom: 1rem;
}
.signup-input-element::placeholder {
  color: #999;
}
.signup-input-element:focus {
  border: 1px solid #ccc;
}
.signup-input-element:hover {
  border-color: #666;
}
.signup-password-note {
  color: #999;
  font-size: var(--font-vsmall);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 1rem;
}
.signup-password-note-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.signup-password-note-checkmark {
  color: var(--app-green);
  font-size: var(--font-vsmall);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 1rem;
}
.signup-password-no-match {
  color: #ff0000;
  font-size: var(--font-vsmall);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 1rem;
}
.signup-create-account-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.25rem;
}
.signup-create-account-container button {
  padding: 10px 20px;
  border: 0px;
  border-radius: 6px;
  background-color: var(--app-green);
  color: var(--app-white);
  font-size: var(--font-small-medium);
  cursor: pointer;
}
.signup-create-account-container button:disabled {
  padding: 10px 20px;
  border: 0px;
  border-radius: 6px;
  background-color: var(--app-green);
  color: var(--app-white);
  font-size: var(--font-small-medium);
  cursor: not-allowed;
}
.signup-checkbox-item-option {
  padding: 10px 20px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
}
.signup-checkbox-item-option input[type="checkbox"] {
  margin-right: 0.5rem;
}
.signup-checkbox-item-option.selected {
  background-color: #336699;
  color: var(--app-white);
}
.password-fields-title-group {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1rem;
}
.password-fields-title-group svg {
  width: 25px;
  max-width: 25px;
  margin-bottom: 0.5rem;
}
