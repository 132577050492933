/* CSS Properties For Tabbed Container Component */

/* Primary */
.tabbed-container {
  background: transparent;
  margin: 0;
  padding: 0;
  max-height: 35px;
}
.tab-list {
  display: flex;
  font-weight: var(--font-weight-reg);
  font-size: var(--font-medium);
  line-height: 30px;
  justify-content: right;
  background-color: #f2f3f7;
  z-index: 5;
  width: 100%;
  cursor: pointer;
}

.tab-content {
  padding-top: 1%;
}
ul.tab-list {
  margin: 0;
  list-style-type: none;
  max-height: 35px;
  overflow: hidden;
  padding-right: 20px;
}

ul.tab-list > li.active {
  z-index: 2;
  background: #efefef;
}

ul.tab-list > li.active:before {
  border-color: transparent #efefef transparent transparent;
}

ul.tab-list > li.active:after {
  border-color: transparent transparent transparent #efefef;
}

ul.tab-list > li {
  float: right;
  margin: 5px -10px 0;
  border-top-right-radius: 25px 170px;
  border-top-left-radius: 20px 90px;
  padding: 0 30px 0 25px;
  height: 170px;
  background: #ddd;
  position: relative;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  min-width: 200px;
  max-width: 200px;
  text-align: center;
}

ul.tab-list > li > a {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none;
  color: #222;
}

ul.tab-list > li:before,
ul.tab-list > li:after {
  content: "";
  background: transparent;
  height: 20px;
  width: 20px;
  border-radius: 100%;
  border-width: 10px;
  top: 0px;
  border-style: solid;
  position: absolute;
}

ul.tab-list > li:before {
  border-color: transparent #ddd transparent transparent;
  -webkit-transform: rotate(48deg);
  -moz-transform: rotate(48deg);
  -ms-transform: rotate(48deg);
  -o-transform: rotate(48deg);
  transform: rotate(48deg);
  left: -23px;
}

ul.tab-list > li:after {
  border-color: transparent transparent transparent #ddd;
  -webkit-transform: rotate(-48deg);
  -moz-transform: rotate(-48deg);
  -ms-transform: rotate(-48deg);
  -o-transform: rotate(-48deg);
  transform: rotate(-48deg);
  right: -17px;
}
