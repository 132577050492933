/* CSS Properties For All Visualizations */

@import "visualizations/BarChart.css";
@import "visualizations/BoxPlotChart.css";
@import "visualizations/DoughnutChart.css";
@import "visualizations/ForecastChart.css";
@import "visualizations/GaugeChart.css";
@import "visualizations/LineChart.css";

.chart-card-container {
  margin-top: 0rem;
  height: 24rem;
}
