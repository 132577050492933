/* CSS Properties For All Layouts */
@import "layouts/NavigationBar.css";

/* Application Layout */
#application-layout-container {
  display: block;
  height: 100vh;
  background-color: var(--background-color-main);
  padding: 4.5rem 2rem 0rem 2rem;
}

/* Application Header */
.page-title {
  color: var(--text-color-main);
  font-size: var(--font-page-title);
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}
.company-name-title {
  color: var(--text-color-label);
  font-size: var(--font-medium);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.page-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 0.75rem;
}

/* Auth Header */
.auth-header-main-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
}
.auth-header-main-container img {
  width: 175px;
  max-width: 175px;
}
.auth-header-main-container button {
  padding: 10px 20px;
  border: 0px solid #ccc;
  border-radius: 6px;
  background-color: rgb(-0, 0, 28);
  color: var(--app-white);
  font-size: var(--font-small-medium);
  cursor: pointer;
}

/* Colored Bar Background */
.colored-bar-background-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: url("/src/assets/colorFormat.png") repeat;
  background-position: right bottom;
  opacity: 0.1;
  z-index: -1;
}
.colored-bar-main-container-content {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100vw;
  height: 96vh;
  margin: 10px auto;
  z-index: 0;
}
