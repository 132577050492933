/* CSS Properties For Metric Tile Group Mini Component */

/* Primary */
.mini-metric-tile-group-container {
  border-radius: 16px;
  height: 10rem;
  overflow: hidden;
  width: inherit;
  position: relative;
  background-color: var(--app-white);
  display: grid;
  grid-template-columns: 98% 2%;
}
.mini-metric-tile-group-content {
  padding: 1.25rem 1.25rem 1.25rem 1.25rem;
}
.mini-metric-tile-group-title {
  color: var(--text-color-main);
  font-size: var(--font-small-medium);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  align-items: center;
  text-align: center;
}
.mini-metric-tile-group-metric-group {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0.5rem 0rem;
  justify-content: center;
  align-items: center;
}
.mini-metric-tile-group-metric {
  font-size: var(--font-large);
  font-weight: 800;
  letter-spacing: 0em;
  text-align: left;
  color: var(--text-color-main);
  line-height: 70px;
}
.mini-metric-tile-group-mini-metric {
  font-size: var(--font-xxlarge);
  font-weight: 800;
  letter-spacing: 0em;
  text-align: left;
  color: var(--text-color-main);
  line-height: 70px;
}
.mini-metric-tile-group-unit {
  font-size: var(--font-small-medium);
  font-weight: 500;
  letter-spacing: 0em;
  text-align: left;
  color: var(--text-color-label);
  line-height: 25px;
  margin-left: 2rem;
}
