/* CSS Properties For Toggle Switch Component */

/* Primary */
.toggle-switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
  background-color: #ccc;
  border-radius: 20px;
  overflow: hidden;
  font-size: var(--font-small);
  text-align: center;
}

.toggle-switch input[type="checkbox"] {
  display: none;
}

.toggle-switch .switch {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  transition: background-color 0.2s ease;
}

.toggle-switch .switch::before {
  position: absolute;
  content: "";
  left: 2px;
  top: 1px;
  width: 18px;
  height: 18px;
  background-color: var(--app-white);
  border-radius: 50%;
  transition: transform 0.3s ease;
  z-index: 1;
  box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.5);
}

.toggle-switch input[type="checkbox"]:checked + .switch::before {
  transform: translateX(18px);
  background-color: var(--app-white);
}

.toggle-switch input[type="checkbox"]:checked + .switch {
  background-color: #336699;
}

.switch-label {
  position: relative;
  z-index: 2;
  flex: 1;
}

.switch-label-left {
  color: #fff;
  padding-left: 10px;
}

.switch-label-right {
  color: #aaa;
  padding-right: 10px;
}

.toggle-switch input[type="checkbox"]:checked + .switch .switch-label-left {
  color: #aaa;
}

.toggle-switch input[type="checkbox"]:checked + .switch .switch-label-right {
  color: #fff;
}

/* Disabled State */
.toggle-switch.disabled {
  opacity: 0.6; /* Gray out the switch */
  cursor: not-allowed; /* Change cursor to not-allowed */
}

.toggle-switch.disabled .switch {
  pointer-events: none; /* Prevent any interaction */
}

.toggle-switch.disabled .switch::before {
  background-color: #e0e0e0; /* Slightly gray out the knob */
}

.toggle-switch.disabled .switch-label-left,
.toggle-switch.disabled .switch-label-right {
  color: #ccc; /* Gray out the labels */
}
