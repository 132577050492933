/* CSS Properties For Settings Page Specific Components */
@import "/src/pages/SettingsPage/VinDecoderSettings.css";

#settings-page-container,
#veh-settings-page-container {
  display: flex;
  flex-direction: column;
  height: fit-content;
  background-color: #ffffff;
  border-radius: 6px;
  padding: 1rem 1.75rem;
}

.setting-co-domain-name-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 20px 0;
}
.setting-co-domain-name-group input,
.setting-user-info-subgroup input,
.setting-user-info-subgroup p,
.setting-account-setup-subgroup input,
.setting-account-setup-subgroup p {
  margin-left: 20px;
}
.setting-co-domain-name-group input {
  width: 400px;
}
.setting-user-info-group,
.setting-user-info-subgroup,
.setting-account-setup-group,
.setting-account-setup-subgroup {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.setting-user-info-subgroup,
.setting-account-setup-subgroup,
.setting-organization-group p {
  margin: 20px 0;
}
.setting-account-setup-subgroup button {
  background-color: var(--background-color-nav);
  color: var(--app-white);
  border-radius: 6px;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}
.setting-organization-group {
  display: flex;
  flex-direction: column;
}
.settings-resetpw-modal-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;
}
.settings-resetpw-modal-group button {
  padding: 10px 70px;
  border: 0px;
  border-radius: 6px;
  background-color: var(--app-green);
  color: var(--app-white);
  font-size: var(--font-small-medium);
  cursor: pointer;
}

.settings-resetpw-modal-group button:disabled:hover::before {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  margin-top: 4rem;
  color: var(--app-white);
  background: rgba(10, 10, 7, 0.7);
  border-radius: 5px;
  padding: 10px;
  width: 200px;
  content: "Please enter valid credentials";
  font-size: var(--font-small);
}

.settings-resetpw-modal-group button:disabled {
  cursor: not-allowed;
}
