/* CSS Properties For Reduction Page Specific Components */

/* Reduction Page Container */
#reduction-page-container {
  display: grid;
  width: 100%;
  grid-template-columns: 50% 50%;
  gap: 0.5rem;
}
/* Emission Reduction Target */
.emission-reduction-target-container {
  background-color: var(--app-white);
  padding: var(--padding-2);
  border-radius: 1rem;
  width: 90%;
  height: 27rem;
  flex-shrink: 0;
}
.emission-reduction-target-container-title-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.emission-reduction-target-container-title-group svg {
  color: var(--text-color-main);
}
.emission-reduction-target-dropdown-group {
  display: grid;
  grid-template-columns: 10% 15% 22% 15% 25%;
  gap: 1rem;
  align-items: center;
  margin-top: 1rem;
}

/* Emissions Forecasting Contianer */
.emission-forecasting-container {
  background-color: var(--app-white);
  padding: var(--padding-2);
  border-radius: 1rem;
  height: 27rem;
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.forecast-disabled-container {
  background-color: var(--text-color-label);
  width: 100%;
  height: 100%;
  z-index: 100;
  opacity: 0.6;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.forecast-disabled-container p {
  color: var(--app-white);
  font-size: var(--font-small-medium);
  font-weight: 500;
  margin-bottom: 1rem;
  padding: 20px;
  text-align: center;
}
.emission-settings-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border-radius: 0.25rem;
  color: var(--text-color-label);
  padding: 0.5rem 0.5rem;
  border: none;
  cursor: pointer;
  width: 20px;
  height: 20px;
}
.emi-reduct-target-settings-container {
  background-color: #f5f5f5f5;
  margin: 1rem 0rem;
  padding: var(--padding-2);
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 7rem;
}
.emission-reduc-target-settings-title-group {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.emission-reduc-target-settings-title-group svg {
  color: var(--text-color-main);
  margin-left: 0.5rem;
}
.emi-reduc-target-settings-group {
  display: grid;
  grid-template-columns: 30% 70%;
  align-items: center;
}
.emi-reduct-target-settings-sub-text p {
  color: var(--text-color-label);
  font-size: var(--font-small);
  margin: 0.5rem;
}
.emi-reduct-target-settings-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border-radius: 0.25rem;
  color: var(--text-color-label);
  padding: 0.5rem 0.5rem;
  border: none;
  cursor: pointer;
  width: 25px;
  height: 25px;
}

/* Mobile View */
@media screen and (max-width: 900px) {
  #reduction-page-container {
    display: flex;
    flex-direction: column;
  }
  .emission-reduction-target-container {
    height: fit-content;
    max-height: fit-content;
    min-height: fit-content;
  }
}
