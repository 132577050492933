/* CSS Properties For Range Slider Component */

/* Primary */
.range-slider {
  width: 90%;
  height: 1px;
  background: black;
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-left: 10px;
  margin-right: 10px;
  z-index: 0;
}

.range-slider .thumb {
  width: 10px;
  height: 10px;
  cursor: grab;
  background: transparent;
  border-radius: 50%;
  border: 1px solid black;
  margin-top: -4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
.range-slider .thumb:before {
  content: "";
  width: 100%;
  height: 100%;
  background: white;
  border-radius: 50%;
}
.range-slider .value-label {
  font-size: var(--font-tiny);
  position: absolute;
  top: 100%;
  transform: translateY(4px);
}
.range-slider .thumb-0 {
  margin-left: -2px;
}
.range-slider .thumb-1 {
  margin-left: 2px;
}
.range-slider .thumb:hover,
.range-slider .thumb:active {
  background: #b1e4f4;
}
.input-range-slider-group {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  width: inherit;
  padding: 1.75%;
  gap: 100px;
  margin-top: 25px;
  margin-bottom: 15px;
}
#min-input-range-slider,
#max-input-range-slider {
  border: 0.5px solid black;
  border-radius: 5px;
  padding: 1px;
  font-size: var(--font-tiny);
  text-align: center;
  align-items: center;
}
