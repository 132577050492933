/* CSS Properties For Emissions Page Specific Components */
@import "/src/pages/EmissionsPage/Scope2Manager.css";

/* Main Container */
#emission-page-container-scope1 {
  display: grid;
  grid-template-columns: 77% 23%;
  width: 100%;
  gap: 1rem;
}
#emission-page-container-scope2 {
  display: grid;
  grid-template-columns: 23% 77%;
  width: 100%;
  margin-top: 1rem;
  gap: 1rem;
}
#emission-page-container-scope2-empty {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  color: var(--text-color-label);
  line-height: 1.75rem;
  text-align: center;
}
/* Scope Chart Card */
.scope-card-contianer-group-scope1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 99%;
}
.scope-card-contianer-group-scope2 {
  display: flex;
  flex-direction: row;
  width: 99%;
}
.emissions-page-chart-container {
  margin-top: 2rem;
  height: 15.25rem;
  width: 100%;
}

.scope-metric-tile-group-metric {
  font-size: var(--font-xlarge);
  font-weight: 800;
  letter-spacing: 0em;
  text-align: left;
  color: var(--text-color-main);
  line-height: 70px;
}
.scope-metric-tile-group-metric-sm {
  font-size: var(--font-large);
  font-weight: 800;
  letter-spacing: 0em;
  text-align: left;
  color: var(--text-color-main);
  line-height: 70px;
}
.scope-metric-tile-group-unit {
  font-weight: 500;
  letter-spacing: 0em;
  text-align: left;
  color: var(--text-color-label);
  line-height: 45px;
  margin-left: 1rem;
}
.scope-metric-tile-group-unit-sm {
  font-size: var(--font-tiny);
  font-weight: 500;
  letter-spacing: 0em;
  text-align: left;
  color: var(--text-color-label);
  line-height: 45px;
  margin-left: 0.5rem;
}
.scope2-manage-address-group {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.scope2-entry-form-group {
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  background-color: #f5f5f5;
  border-radius: 6px;
}
.scope2-manage-grid-group {
  display: grid;
  grid-template-columns: 50% 50%;
}
.scope2-manage-input-group-address {
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
  width: 100%;
}
.scope2-manage-input-group-address p {
  margin: 0.25rem;
}
.scope2-manage-input-element {
  width: 90%;
}

/* Lifecycle Page */
.life-glec-page-group {
  display: grid;
  grid-template-columns: 20% 80%;
  width: 100%;
  gap: 1rem;
}
.life-glec-sidebar-group {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
}
.life-glec-card-group {
  display: grid;
  grid-template-rows: repeat(3, minmax(0, 1fr));
  gap: 1rem;
  height: 100%;
}
.life-glec-metric-tile-group-container {
  border-radius: 16px;
  overflow: hidden;
  width: inherit;
  height: 8rem;
  position: relative;
  background-color: var(--app-white);
  padding: 1.5rem;
  align-items: center;
}
.life-glec-metric-tile-group-simple-value {
  display: flex;
  font-size: var(--font-xxlarge);
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin-top: 0.5rem;
  justify-content: center;
  color: var(--text-color-main);
}
.life-glec-group-switch-carousel {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  background-color: #ffffff;
  border-radius: 6px;
  padding: var(--padding-025);
}
.life-glec-chart-header-container {
  display: grid;
  grid-template-columns: 70% 30%;
}
.life-glec-page-chart-container {
  margin-top: 2rem;
  height: 23rem;
  width: 100%;
}
.life-glec-chart-card-container-group {
  background-color: var(--app-white);
  border-radius: 1rem;
  padding: var(--padding-2);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.life-glec-selected-fuel-type-group {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.life-glec-selected-fuel-type {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: var(--text-color-label);
  margin-top: 0.25rem;
}
.life-glec-selected-fuel-type p {
  margin: 0;
  margin-right: 1rem;
}
.life-glec-selected-fuel-clear {
  cursor: pointer;
  color: var(--text-color-label);
  margin-top: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 0.25rem;
}
.life-glec-error-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  color: var(--text-color-label);
  background-color: var(--app-white);
  border-radius: 1rem;
  padding: var(--padding-2);
}
.life-glec-no-data-container {
  display: flex;
  flex-direction: row;
}
.life-glec-no-data-container p {
  margin: 1rem;
  color: var(--text-color-main);
}
/* Delay in Hover Annimcation */
@keyframes showMessage {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Mobile View */
@media screen and (max-width: 900px) {
  #emission-page-container-scope1,
  #emission-page-container-scope2,
  .life-glec-page-group {
    display: flex;
    flex-direction: column;
  }
}
