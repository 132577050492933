/* CSS Properties For Table Component */

/* Primary */
.primary-table {
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}
.primary-table-head {
  line-height: 2rem;
}
.primary-table-row {
  line-height: 2rem;
  min-height: 1.75rem;
  border-top: 0.5px solid #c7cacb;
  border-bottom: 0.5px solid #c7cacb;
  font-size: var(--font-vsmall);
}
.primary-table-row:nth-child(odd) td {
  background-color: #f5f5f5f5;
  vertical-align: middle;
  background: #f5f5f5f5;
  word-wrap: break-word;
  max-width: 200px;
  min-width: 50px;
  padding-left: 0.1rem;
}
.primary-table-row:nth-child(even) td {
  background-color: var(--app-white);
  vertical-align: middle;
  word-wrap: break-word;
  max-width: 200px;
  min-width: 100px;
  padding-left: 0.1rem;
}

.primary-table-sort-header-group {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: var(--app-white);
  height: 35px;
  align-items: top;
}
.primary-table-sort-header {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.primary-table-sort-header svg {
  margin-left: 0.25rem;
  cursor: pointer;
  font-size: var(--font-small);
  color: var(--text-color-label);
}
.sort-table-icons {
  display: flex;
  flex-direction: column;
}

.table-link-col-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-right: 0.5rem;
  color: var(--app-primary-color);
  text-decoration: none;
  font-size: var(--font-small);
  font-weight: 500;
  line-height: 1.25rem;
  letter-spacing: 0.0178571429em;
}
.table-link-col-group:hover {
  text-decoration: underline;
  cursor: pointer;
}
.table-edit-col-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}
.secondary-table-row {
  line-height: 2rem;
  min-height: 1.75rem;
  border-top: 0.5px solid #c7cacb;
  border-bottom: 0.5px solid #c7cacb;
  font-size: var(--font-vsmall);
}
.secondary-table-row:nth-child(odd) td {
  background-color: #f5f5f5f5;
  vertical-align: middle;
  background: #f5f5f5f5;
  word-wrap: break-word;
  max-width: 200px;
  padding-left: 0.1rem;
}
.secondary-table-row:nth-child(even) td {
  background-color: var(--app-white);
  vertical-align: middle;
  word-wrap: break-word;
  max-width: 200px;
  padding-left: 0.1rem;
}
.primary-table-search-group {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.5rem;
}
.primary-table-search-input {
  padding: 0.15rem;
  border: 1px solid #c7cacb;
  border-radius: 0.25rem;
  font-size: var(--font-vsmall);
  width: 75%;
}
.primary-table-search-input input {
  width: 100%;
}
.primary-table-search-clear {
  margin-left: -1rem;
  background-color: var(--app-red);
  border: none;
  color: var(--app-white);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.25rem;
  border-radius: 5px;
  cursor: pointer;
  font-size: var(--font-small);
  position: relative;
}
